// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Referral {
  id: string;
  source_platform: string;
  listing_sid: string;
  time: number;
  source_type: string;
  visitor_id: string;
}

export interface UpdateReferralDto {
  id?: string;
  listing_sid?: string;
  time?: number;
  source_type?: string;
  visitor_id?: string;
}

export interface CreateLineVisitorDto {
  id: string;
  displayName: string;
  pictureUrl: string;
  ref?: string;
}

export interface ReferralLine {
  id: string;
  listing_sid: string;
  time: number;
  source_type: string;
  visitor_id: string;
}

export interface Recipient {
  id: string;
}

export interface Sender {
  id: string;
}

export interface PostBack {
  referral: object;
  title: string;
  payload: string;
  mid: string;
}

export interface Messaging {
  recipient: Recipient;
  timestamp: number;
  sender: Sender;
  referral: Referral;
  postback?: PostBack;
  message?: object;
  delivery: object;
}

export interface Standby {
  recipient: Recipient;
  sender: Sender;
  delivery: object;
  timestamp: number;
}

export interface Changes {
  value: object;
  field: object;
}

export interface Entry {
  id: string;
  time: number;
  messaging: Messaging[];
  standby: Standby[];
  changes: Changes[];
}

export interface CreateMetaWebhookDto {
  object?: string;
  entry: Entry[];
}

export enum CommonStatusEnum {
  Active = "active",
  Inactive = "inactive",
}

export interface ListingImage {
  status?: CommonStatusEnum;
  id: string;
  listing_id: string | null;
  google_drive_file_id: string | null;
  watermark_image: string | null;
  order: number | null;
  original_image: string | null;
  tag: string | null;
}

export enum ActivityStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface BuildingImage {
  status: ActivityStatus;
  id: string;
  buildingId: string;
  watermark: string | null;
  googleDriveIdForWatermark: string | null;
  original: string | null;
  googleDriveIdForOriginal: string | null;
  order: number;
  tag: string | null;
}

export interface UploadBlogImageResultEntity {
  url: string;
  googleId: string;
}

export interface AddVideoDto {
  listingId: string;
  order?: number;
  type?: string;
  url: string;
}

export interface ListingVideo {
  status?: CommonStatusEnum;
  id: string;
  video_url: string | null;
  type: string | null;
  order: number | null;
  listing_id: string | null;
}

export enum VideoSource {
  YOUTUBE = "YOUTUBE",
}

export interface AddVideoForBuildingDto {
  type?: VideoSource;
  buildingId: string;
  order?: number;
  url: string;
}

export interface BuildingVideo {
  source: VideoSource;
  status: ActivityStatus;
  id: string;
  videoUrl: string;
  order: number;
  buildingId: string;
}

export interface UploadToGoogle {
  originalImageResultURL: string;
}

export enum GoogleBucketEnum {
  Thumbnail = "thumbnail",
  General = "general",
  Template = "template",
  Publication = "publication",
  ListingPresentation = "listing-presentation",
  SocialMedia = "social-media",
  Building = "building",
}

export interface UploadToGoogleSpecificBucketDto {
  /** @format binary */
  file: File;
  bucket: GoogleBucketEnum;
  filename: string;
}

export enum AgentServiceLocationEnum {
  TW_ALL = "TW_ALL",
  US_NY = "US_NY",
  US_SEATTLE = "US_SEATTLE",
  US_SF = "US_SF",
  US_LA = "US_LA",
  US_BOSTON = "US_BOSTON",
  US_CA_FULLERTON = "US_CA_FULLERTON",
  US_CA_IRVINE = "US_CA_IRVINE",
  CA_VANCOUVER = "CA_VANCOUVER",
}

export interface AgentServiceLocation {
  location?: AgentServiceLocationEnum;
  id: string;
  agent_id: string;
}

export interface Agent {
  pictures?: string[] | null;
  agent_service_location?: AgentServiceLocation[] | null;
  id: string;
  name: string | null;
  chinese_name: string | null;
  english_name: string | null;
  bio: string | null;
  email: string;
  contact_phone: string | null;
  contact_fb: string | null;
  contact_ig: string | null;
  contact_line: string | null;
  license: string | null;
  active: boolean | null;
  sid: string;
  group: string;
  order: number;
  qrcode_picture_line: string | null;
}

export interface CreateNewAgentDto {
  pictures?: string[] | null;
  agent_service_locations: AgentServiceLocationEnum[];
  name: string | null;
  chinese_name: string | null;
  english_name: string | null;
  bio: string | null;
  email: string;
  contact_phone: string | null;
  contact_fb: string | null;
  contact_ig: string | null;
  contact_line: string | null;
  license: string | null;
  group: string;
  qrcode_picture_line: string | null;
}

export interface UpdateAgentDataDto {
  pictures?: string[] | null;
  agent_service_locations?: AgentServiceLocationEnum[];
  id: string;
  name?: string | null;
  chinese_name?: string | null;
  english_name?: string | null;
  bio?: string | null;
  email?: string;
  contact_phone?: string | null;
  contact_fb?: string | null;
  contact_ig?: string | null;
  contact_line?: string | null;
  license?: string | null;
  group?: string;
  qrcode_picture_line?: string | null;
}

export interface AgentResponsibleListingsEntity {
  sid: string;
  title: string;
}

export interface UpdateAgentDto {
  pictures?: string[] | null;
  id: string;
  name?: string | null;
  chinese_name?: string | null;
  english_name?: string | null;
  bio?: string | null;
  email?: string;
  contact_phone?: string | null;
  contact_fb?: string | null;
  contact_ig?: string | null;
  contact_line?: string | null;
  license?: string | null;
  active?: boolean | null;
  sid?: string;
  group?: string;
  order?: number;
  qrcode_picture_line?: string | null;
}

export interface UpdateAgentSortDto {
  id: string;
  order: number;
}

export interface CreateMailDto {
  to: string;
  from?: string;
  subject: string;
  text: string;
  html: string;
}

export interface SendVerifyEmailDto {
  email: string;
}

export interface InstagramIssueEntity {
  authURL: string;
}

export interface ConnectInstagramIdWithUserDto {
  instagramId: string;
  instagramToken: string;
}

export interface UserRoleEntity {
  id: string;
  role: string;
  user_id: string;
}

export interface BackyardUserEntity {
  role?: "admin" | "user";
  organization_id: string;
  email: string | null;
  id_token: string | null;
  google_oauth: boolean;
  facebook_oauth: boolean;
  crm_id: string | null;
  facebook_id: string | null;
  facebook_token: string | null;
  instagram_id: string | null;
  instagram_token: string | null;
  id: string;
  active: boolean;
  name: string | null;
  image: string | null;
  crm_authToken: string | null;
  crm_name: string | null;
  user_role: UserRoleEntity[];
}

export interface ConnectFacebookIdWithUserDto {
  facebookId: string;
  facebookToken: string;
}

export interface AuthIssueGoogleOauthEntity {
  authURL: string;
}

export interface GoogleAuthCallbackDto {
  code: string;
  scope: string;
  authuser: string;
  hd: string;
  prompt: string;
  redirect_url: string;
}

export interface PasswordAuth {
  pwd: string;
  usr: string;
}

export interface PwdAuthEntity {
  authToken: string;
  userId: string;
  image: string;
  name: string;
  jwt: string;
  email: string;
}

export interface VerifyEmailDto {
  code: string;
  email: string;
}

export enum CountryEnum {
  TW = "TW",
  US = "US",
  CA = "CA",
}

export enum ListingStatus {
  SOLD = "SOLD",
  ON_SALE = "ON_SALE",
}

export interface PaginationPagingDto {
  page: number;
  pageSize: number;
  offset?: number;
}

export interface SearchListingPaginationDto {
  country?: CountryEnum[];
  listingStatus?: ListingStatus;
  paging: PaginationPagingDto;
  cities?: string[];
  buildingType?: string[];
  deprioritizeSoldItem?: boolean;
  onlyActivate?: boolean;
}

export interface PaginationEntity {
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface SearchListingSortingDto {
  sortBy: "price" | "current_deployed_at";
  sort: "ASC" | "DESC";
}

export interface SearchListingDto {
  country?: CountryEnum[];
  listingStatus?: ListingStatus;
  cities?: string[];
  buildingType?: string[];
  deprioritizeSoldItem?: boolean;
  sorting?: SearchListingSortingDto;
  onlyActivate?: boolean;
  paging: PaginationPagingDto;
}

export enum ListingStatusEnum {
  Active = "active",
  Inactive = "inactive",
}

export enum StateOfListing {
  NONE = "NONE",
  NEW = "NEW",
  OPEN_HOUSE = "OPEN_HOUSE",
  DISCOUNT = "DISCOUNT",
  PENDING = "PENDING",
  SOLD = "SOLD",
  EXCLUSIVE = "EXCLUSIVE",
}

export interface ListingState {
  state: StateOfListing;
  id: string;
  listing_id: string;
  label: string | null;

  /** @format date-time */
  expire_at: string | null;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
}

export interface ListingListEntity {
  country?: CountryEnum;
  status?: ListingStatusEnum;
  listing_state: ListingState[];
  listing_image: ListingImage[];
  id: string;
  sid: string;
  price: number | null;
  dealPrice: number | null;
  room: string | null;
  bath: string | null;
  common_space: string | null;
  detail_total_area_size: string | null;
  title: string;
  display_building_project: boolean;
  building_project: string | null;
  display_address: string | null;

  /** @format date-time */
  sold_at: string | null;
  feature_content: string | null;
  state: string | null;
  city: string | null;
}

export interface ListingListPaginationEntity {
  payload: ListingListEntity[];
  page: number;
  pageSize: number;
  totalCount: number;
}

export enum GuardManagementTime {
  OTHER = "OTHER",
  WHOLE_DAY = "WHOLE_DAY",
  HALF_DAY = "HALF_DAY",
}

export enum ContractTypeEnum {
  UNIQUE = "UNIQUE",
  COMMON = "COMMON",
}

export interface ParkingDetailEntity {
  num: number;
  parking_space_type: string;
}

export interface TrafficEnvEntity {
  distance?: string;
  gType?: string;
  location: { coordinates?: number[]; lat?: number; lng?: number; type?: string };
  m?: number;
  name?: string;
  type?: string;
  walkTime?: string;
  driveTime?: string;
}

export enum MainWindowFacingEnum {
  E = "E",
  W = "W",
  S = "S",
  N = "N",
  ES = "ES",
  EN = "EN",
  WS = "WS",
  WN = "WN",
}

export enum DecorLevelEnum {
  HIGH = "HIGH",
  MIDDLE = "MIDDLE",
  BASIC = "BASIC",
  RAW = "RAW",
}

export interface ListingAdvancedInfoEntity {
  main_window_facing?: MainWindowFacingEnum | null;
  decor_level?: DecorLevelEnum | null;
  id: string;
  listing_id: string;
  land_usage: string | null;
  apartment_complex_name: string | null;
  is_apartment_complex_name_displayable: boolean;
  builder_project_name: string | null;
  public_usage_ratio: number | null;
  house_per_floor: number | null;
  is_renting: boolean | null;
  year_of_renting_till: number | null;
  month_of_renting_till: number | null;
  date_of_renting_till: number | null;
  nearby_school: string | null;
  note: string | null;
}

export interface ListingHashtagEntity {
  id: string;
  hashtag: string;
  hyperlink: string | null;
  active: boolean;

  /** @format date-time */
  created_at: string;
  listingId: string | null;
  order: number;
}

export type Decimal = object;

export interface ListingDetail {
  detail_guard_management_times: GuardManagementTime;
  contract_type?: ContractTypeEnum;
  deployed_at?: string[];
  parking_detail?: ParkingDetailEntity[] | null;
  traffic_env_school?: TrafficEnvEntity[] | null;
  traffic_env_hospital?: TrafficEnvEntity[] | null;
  traffic_env_supermarket?: TrafficEnvEntity[] | null;
  traffic_env_traffic?: TrafficEnvEntity[] | null;
  traffic_env_recreation?: TrafficEnvEntity[] | null;
  traffic_env_corporate?: TrafficEnvEntity[] | null;
  country?: CountryEnum;
  status?: ListingStatusEnum;
  feature_tags?: string[] | null;
  picture_tags?: string[] | null;
  raw_image_urls?: string[] | null;
  watermark_image_urls?: string[] | null;
  youtube_video_ids?: string[] | null;
  detail_sorting_categories?: string[] | null;
  house_available_time?: string[];
  listing_state: ListingState[];
  listing_image: ListingImage[];
  listing_video: ListingVideo[];
  listing_advanced_info?: ListingAdvancedInfoEntity | null;
  listing_hashtag: ListingHashtagEntity[];
  id: string;
  sid: string;
  legacyid: string | null;
  buildingId: string | null;
  address: string | null;
  agent1_id: string;
  agent2_id: string | null;
  bath: string | null;
  building_project: string | null;
  city: string | null;
  state: string | null;
  common_space: string | null;
  display_address: string | null;
  district: string | null;
  group: string;
  order: number;
  feature_content: string | null;
  price: number | null;
  dealPrice: number | null;
  price_without_parking_slot: number;
  parking_slot_total_price: number;
  room: string | null;
  balcony: number | null;
  title: string;
  detail_purpose: string | null;
  detail_category: string | null;
  detail_floor: string | null;
  detail_age: string | null;
  detail_total_area_size: string | null;
  detail_main_area_size: string | null;
  detail_other_area_size: string | null;
  detail_amenities_area: string | null;
  detail_lot_size: string | null;
  detail_parking: string | null;
  detail_balcony: string | null;
  detail_amenities: string | null;
  detail_management_fee: string | null;
  detail_land_category: string | null;
  detail_land_purpose: string | null;
  detail_land_size: string | null;
  detail_land_building: boolean | null;
  detail_guard_management: boolean | null;
  location_latitude: string | null;
  location_longitude: string | null;
  detail_parking_size: string | null;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  folderid: string | null;

  /** @format date-time */
  sold_at: string | null;

  /** @format date-time */
  close_at: string | null;
  floor: string | null;
  total_floor: string | null;
  floor_note: string | null;
  open_layout: boolean;
  is_parking_space_in_amenities: boolean;
  unit: string | null;
  sec: string | null;
  no: string | null;
  month_of_building_completion: number | null;
  year_of_building_completion: number | null;
  date_of_completion: string | null;
  display_building_project: boolean;
  zip_code: string | null;
  house_available_time_type: string | null;
  mortgage_amount: Decimal | null;
  owner_available_contact_time: string | null;
  owner_email: string | null;
  owner_has_mortgage: boolean | null;
  owner_house_viewing_way: string | null;
  owner_ideal_price: Decimal | null;
  owner_line: string | null;
  owner_name: string | null;
  owner_phone: string | null;
  owner_sell_reason: string | null;

  /** @format date-time */
  current_deployed_at: string | null;
}

export interface ListingPaginationEntity {
  payload: ListingDetail[];
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface ListingForBackyardList {
  country?: CountryEnum;
  status?: ListingStatusEnum;
  raw_image_urls?: string[] | null;
  listing_state: ListingState[];
  id: string;
  sid: string;
  title: string;
  price: number | null;
  building_project: string | null;
  group: string;
  city: string | null;
  agent1_id: string;

  /** @format date-time */
  updated_at: string;
  address: string | null;
}

export interface ListingForBuy {
  listing: ListingDetail;
  agents: Agent[];
  agentLookup: Record<string, string>;
}

export interface PaginationSortDto {
  sort?: "asc" | "desc";
  field?: string;
}

export interface SearchListingByAddressDto {
  sorts?: PaginationSortDto[];
  keyword: string;
  paging: PaginationPagingDto;
}

export interface CreateListingFromBuildingDto {
  address: string;
  buildingId: string;
}

export interface CreateListingFromCMADto {
  address: string;
  unit?: string;
  sec?: string;
  no?: string;
}

export interface CreateUsListingDto {
  state: string;
  city: string;
  address: string;
}

export interface CreateCanadaListingDto {
  state: string;
  city: string;
  address: string;
}

export interface CreateListingFromEmptyAddressDto {
  country?: "TW" | "US";
  address: string;
}

export interface CloneListingDto {
  listingId: string;
  targetId: string;
}

export interface UpdateTrafficEnvDto {
  listingId: string;
}

export interface MediaEntity {
  active: boolean;
  id: string;
  title: string;

  /** @format date-time */
  published_on: string | null;
  publisher: string | null;
  source: string;
  imageUrl: string;
  iconUrl: string | null;
  reporter: string | null;
  group: string | null;
}

export interface AreaOptions {
  state: string;
  cities: string[];
}

export interface GetOptionsForBuy {
  buildingTypesData: string[];
  citiesData: string[];
  usCitiesData: AreaOptions[];
}

export interface GetOptionsForBuildingFilter {
  twCitiesData: string[];
  usCitiesData: AreaOptions[];
  caCitiesData: AreaOptions[];
}

export enum TEMPLATE_CATEGORY {
  ALL = "ALL",
  LISTING_PRESENTATION = "LISTING_PRESENTATION",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  ONE_PAGE_FLYER = "ONE_PAGE_FLYER",
}

export interface TemplateReferenceEntity {
  type?: "AGENT" | "LISTING";
  refId: string;
}

export enum SCHEMA_TYPES {
  OPF = "OPF",
  HOUSE = "HOUSE",
  AGENT = "AGENT",
}

export interface TemplatePageDataEntity {
  type?: "image" | "string";
  refSchemaType?: SCHEMA_TYPES;
  id: string;
  className?: string;
  locked: boolean;
  name: string;
  value: string;
  value_l?: string;
  refSchema?: string;
}

export interface BookPageEntity {
  reference?: TemplateReferenceEntity;

  /** @format date-time */
  created_at: string;
  created_by: string;
  data: TemplatePageDataEntity[];
  id: string;
  label: string;
  templateId: string | null;
  templateName: string | null;
  templateThumb: string | null;

  /** @format date-time */
  updated_at: string;
}

export interface Book {
  type?: TEMPLATE_CATEGORY;
  pages?: BookPageEntity[] | null;
  title: string | null;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  id: string;
  created_by: string | null;
  cover: string | null;
}

export interface CreateBookDto {
  type?: TEMPLATE_CATEGORY;
}

export interface UpdateBookTitleDto {
  title: string;
  bookId: string;
}

export interface UpdateBookPageDataDto {
  type?: "image" | "string";
  refSchemaType?: SCHEMA_TYPES;
  id?: string;
  className?: string;
  locked?: boolean;
  name?: string;
  value?: string;
  value_l?: string;
  refSchema?: string;
}

export interface UpdateBookPageDto {
  data?: UpdateBookPageDataDto;
  bookId: string;
  pageId: string;
  thumb?: string;
}

export interface BookPageDataEntity {
  type?: "image" | "string";
  refSchemaType?: SCHEMA_TYPES;
  id: string;
  className?: string;
  locked: boolean;
  name: string;
  value: string;
  value_l?: string;
  refSchema?: string;
}

export interface UpdateWholeBookPageDto {
  data?: BookPageDataEntity[];
  bookId: string;
  pageId: string;
  thumb?: string;
}

export interface UpdateBookPageReferenceDto {
  bookId: string;
  pageId: string;
  reference: TemplateReferenceEntity;
}

export interface ResetBookPageDto {
  type?: TEMPLATE_CATEGORY;
  bookId: string;
  pageId: string;
}

export interface TemplateEntity {
  type: TEMPLATE_CATEGORY;
  data: TemplatePageDataEntity[];
  name: string | null;
  body: string | null;
  thumb: string;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  id: string;
  created_by: string | null;
}

export interface CreateTemplateDto {
  type: TEMPLATE_CATEGORY;
  data: TemplatePageDataEntity[];
  name: string | null;
  body: string | null;
  thumb: string;
  created_by: string | null;
}

export interface DuplicateTemplateDto {
  templateId: string;
}

export interface UpdateTemplateDto {
  type?: TEMPLATE_CATEGORY;
  data?: TemplatePageDataEntity[];
  templateId: string;
  name?: string | null;
  body?: string | null;
  thumb?: string;
  created_by?: string | null;
}

export interface TemplatePageEntity {
  reference?: TemplateReferenceEntity;

  /** @format date-time */
  created_at: string;
  created_by: string;
  data: TemplatePageDataEntity[];
  id: string;
  label: string;
  templateId: string | null;
  templateName: string | null;
  templateThumb: string | null;

  /** @format date-time */
  updated_at: string;
}

export interface PublicationEntity {
  type?: TEMPLATE_CATEGORY;
  title: string | null;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  id: string;
  pages: TemplatePageEntity[] | null;
  created_by: string | null;
  cover: string | null;
}

export interface CreatePublicationPageDto {
  label: string;
  operator: string;
  publicationId: string;
}

export interface PublicationPageEntity {
  reference?: TemplateReferenceEntity;

  /** @format date-time */
  created_at: string;
  created_by: string;
  data: TemplatePageDataEntity[];
  id: string;
  label: string;
  templateId: string | null;
  templateName: string | null;
  templateThumb: string | null;

  /** @format date-time */
  updated_at: string;
}

export interface UpdatePublicationDataDto {
  type?: TEMPLATE_CATEGORY;
  title?: string | null;

  /** @format date-time */
  created_at?: string;

  /** @format date-time */
  updated_at?: string;
  id?: string;
  pages?: TemplatePageEntity[] | null;
  created_by?: string | null;
  cover?: string | null;
}

export interface UpdatePublicationDto {
  data: UpdatePublicationDataDto;
  publicationId: string;
}

export interface UpdatePublicationPageDto {
  data: PublicationPageEntity;
  pageId: string;
  publicationId: string;
}

export interface UpdatePublicationPageOrderDto {
  pages: PublicationPageEntity[];
  publicationId: string;
}

export interface FacebookInsightCostPerActionTypeEntity {
  value: number;
}

export interface FacebookInsightEntity {
  cost_per_action_type?: FacebookInsightCostPerActionTypeEntity[];
  spend: string;
  account_currency: string;
  impressions: string;
  reach: string;
  inline_post_engagement: string;
  inline_link_clicks: string;
}

export interface FacebookCampaignEntity {
  id: string;
  name: string;
  status: string;
  objective: string;
  created_time: string;
}

export interface FacebookAudienceEntity {
  id: string;
  name: string;
}

export interface FacebookTargetingEntity {
  targeting: { age_max: number; age_min: number };
}

export interface GetTargetingEntity {
  key: string;
}

export interface FacebookPreviewDataEntity {
  body: string;
}

export interface FacebookPreviewEntity {
  data?: FacebookPreviewDataEntity[];
  config: { params?: { ad_format: string } };
}

export interface FacebookCreateCampaignDto {
  title?: string;
  objective?: string;
}

export interface FacebookCreateAPIErrorEntity {
  code: number;
  error_user_title: string;
  error_user_msg: string;
}

export interface FacebookCreateAdParamsDto {
  title?: string;
  adSetId: string;
  adCreativeId?: string;
}

export interface FacebookAdEntity {
  id: string;
}

export interface FacebookCreateAdSetParamsInterestEntity {
  id: string;
  name: string;
}

export interface FacebookCreateAdSetParamsDto {
  regions?: object[];
  campaignId: string;
  budget: number;

  /** @format date-time */
  startTime?: string;

  /** @format date-time */
  endTime?: string;
  title: string;
  bidAmount: number;
  ageMax?: number;
  ageMin?: number;
  interests?: FacebookCreateAdSetParamsInterestEntity[];
}

export interface FacebookAdSetEntity {
  id: string;
}

export interface FacebookCreateSlideShowDto {
  title: string;
  link?: string;
  file?: object;
  videoId?: string;
  messaging?: string;
}

export interface CreateSlideShowDto {
  id: string;
}

export interface FacebookCreateCreativeParamsDto {
  title?: string;
  link?: string;
  image_hash: string;
  messaging: string;
}

export interface FacebookCreativeEntity {
  id: string;
}

export interface FacebookUploadImageDto {
  image_url?: string;
  image?: string;
}

export interface FacebookImageEntity {
  images: { bytes?: { hash: string } };
}

export interface FacebookUpdateCampaignDto {
  campaignId: string;
  title: string;
}

export interface FacebookUpdateAdParamsDto {
  adId: string;
  title?: string;
  adSetId: string;
  adCreativeId?: string;
}

export interface FacebookUpdateAdSetParamsDto {
  regions?: object[];
  adSetId: string;
  campaignId: string;
  budget: number;

  /** @format date-time */
  startTime?: string;

  /** @format date-time */
  endTime?: string;
  title: string;
  bidAmount: number;
  ageMax?: number;
  ageMin?: number;
  interests?: FacebookCreateAdSetParamsInterestEntity[];
}

export interface FacebookUpdateCreativeParamsDto {
  adCreativeId: string;
  title?: string;
  link?: string;
  image_hash: string;
  messaging: string;
}

export interface VisitorInInquiriesQueuedEntity {
  _id: string;
  username: string;
  token: string;
  status: string;
}

export interface SourceInInquiriesQueuedEntity {
  type: string;
  id: string;
  alias: string;
  label: string;
  sidebarIcon: string;
  defaultIcon: string;
}

export interface UserOfLastMessageInInquiriesQueuedEntity {
  _id: string;
  username: string;
  name: string;
}

export interface UrlOfLastMessageInInquiriesQueuedEntity {
  url: string;
}

export interface ValueMdOfLastMessageInInquiriesQueuedEntity {
  type: string;
  value: object;
}

export interface MdOfLastMessageInInquiriesQueuedEntity {
  type: string;
  value: ValueMdOfLastMessageInInquiriesQueuedEntity[];
}

export interface LastMessageInInquiriesQueuedEntity {
  _id: string;
  rid: string;
  u: UserOfLastMessageInInquiriesQueuedEntity;
  msg: string;
  ts: string;
  _updatedAt: string;
  urls: UrlOfLastMessageInInquiriesQueuedEntity[];
  mentions: string[];
  channels: string[];
  md: MdOfLastMessageInInquiriesQueuedEntity[];
}

export interface CRMInquiriesQueuedEntity {
  _id: string;
  rid: string;
  name: string;
  ts: string;
  message: string;
  status: string;
  v: VisitorInInquiriesQueuedEntity;
  t: string;
  queueOrder: number;
  estimatedWaitingTimeQueue: number;
  estimatedServiceTimeAt: string;
  source: SourceInInquiriesQueuedEntity;
  _updatedAt: string;
  queuedAt: string;
  lastMessage: LastMessageInInquiriesQueuedEntity;
  id: string;
  full_name: string;
  pic_link: string;
  platform: string;
  target_id: string;
  token: string;
}

export interface GetQueueUsersEntity {
  inquiries: CRMInquiriesQueuedEntity[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
}

export interface CrmVisitorRoomEntity {
  id: string;
  full_name: string | null;
  pic_link: string | null;
  platform: string | null;
  target_id: string | null;
  token: string | null;
  visitor_token: string | null;
  waitingResponse: boolean | null;
  lastMessage: string | null;
  servedBy: string | null;
  updatedAt: string | null;
  roomId: string;
}

export enum CRMAgentLiveChatStatus {
  Available = "available",
  NotAvailable = "not-available",
}

export enum CRMAgentOnlineStatus {
  Online = "online",
  Offline = "offline",
}

export interface CRMAgentEntity {
  role?: "admin" | "user";
  statusLivechat: CRMAgentLiveChatStatus;
  onlineStatus: CRMAgentOnlineStatus;
  agentId?: string;
  organization_id: string;
  email: string | null;
  id_token: string | null;
  google_oauth: boolean;
  facebook_oauth: boolean;
  crm_id: string | null;
  facebook_id: string | null;
  facebook_token: string | null;
  instagram_id: string | null;
  instagram_token: string | null;
  id: string;
  active: boolean;
  name: string | null;
  image: string | null;
  crm_authToken: string | null;
  crm_name: string | null;
  user_role: UserRoleEntity[];
}

export interface DownloadFile {
  path: string;
}

export interface StringMapEntity {
  en: string;
  zh: string;
}

export interface NotifyAgentDto {
  crmId: string;
  contents?: StringMapEntity;
  headings?: StringMapEntity;
  appUrl?: string;
}

export enum ListingEditRecordStatus {
  Merged = "merged",
  ForceMerged = "force_merged",
  Draft = "draft",
  WaitingForReview = "waiting_for_review",
}

export interface ListingHashtagInListingRecordModifyingEntity {
  id?: string;
  hashtag: string;
  hyperlink: string | null;
  order: number;
}

export interface ListingRecordModifyingEntity {
  detail_guard_management_times: GuardManagementTime;
  contract_type?: ContractTypeEnum;
  deployed_at?: string[];
  parking_detail?: ParkingDetailEntity[] | null;
  traffic_env_school?: TrafficEnvEntity[] | null;
  traffic_env_hospital?: TrafficEnvEntity[] | null;
  traffic_env_supermarket?: TrafficEnvEntity[] | null;
  traffic_env_traffic?: TrafficEnvEntity[] | null;
  traffic_env_recreation?: TrafficEnvEntity[] | null;
  traffic_env_corporate?: TrafficEnvEntity[] | null;
  country?: CountryEnum;
  status?: ListingStatusEnum;
  feature_tags?: string[] | null;
  picture_tags?: string[] | null;
  raw_image_urls?: string[] | null;
  watermark_image_urls?: string[] | null;
  youtube_video_ids?: string[] | null;
  detail_sorting_categories?: string[] | null;
  house_available_time?: string[];
  listing_state: ListingState[];
  listing_image: ListingImage[];
  listing_video: ListingVideo[];
  listing_advanced_info?: ListingAdvancedInfoEntity | null;
  listing_hashtag: ListingHashtagInListingRecordModifyingEntity[];
  id: string;
  sid: string;
  legacyid: string | null;
  buildingId: string | null;
  address: string | null;
  agent1_id: string;
  agent2_id: string | null;
  bath: string | null;
  building_project: string | null;
  city: string | null;
  state: string | null;
  common_space: string | null;
  display_address: string | null;
  district: string | null;
  group: string;
  order: number;
  feature_content: string | null;
  price: number | null;
  dealPrice: number | null;
  price_without_parking_slot: number;
  parking_slot_total_price: number;
  room: string | null;
  balcony: number | null;
  title: string;
  detail_purpose: string | null;
  detail_category: string | null;
  detail_floor: string | null;
  detail_age: string | null;
  detail_total_area_size: string | null;
  detail_main_area_size: string | null;
  detail_other_area_size: string | null;
  detail_amenities_area: string | null;
  detail_lot_size: string | null;
  detail_parking: string | null;
  detail_balcony: string | null;
  detail_amenities: string | null;
  detail_management_fee: string | null;
  detail_land_category: string | null;
  detail_land_purpose: string | null;
  detail_land_size: string | null;
  detail_land_building: boolean | null;
  detail_guard_management: boolean | null;
  location_latitude: string | null;
  location_longitude: string | null;
  detail_parking_size: string | null;

  /** @format date-time */
  created_at: string;

  /** @format date-time */
  updated_at: string;
  folderid: string | null;

  /** @format date-time */
  sold_at: string | null;

  /** @format date-time */
  close_at: string | null;
  floor: string | null;
  total_floor: string | null;
  floor_note: string | null;
  open_layout: boolean;
  is_parking_space_in_amenities: boolean;
  unit: string | null;
  sec: string | null;
  no: string | null;
  month_of_building_completion: number | null;
  year_of_building_completion: number | null;
  date_of_completion: string | null;
  display_building_project: boolean;
  zip_code: string | null;
  house_available_time_type: string | null;
  mortgage_amount: Decimal | null;
  owner_available_contact_time: string | null;
  owner_email: string | null;
  owner_has_mortgage: boolean | null;
  owner_house_viewing_way: string | null;
  owner_ideal_price: Decimal | null;
  owner_line: string | null;
  owner_name: string | null;
  owner_phone: string | null;
  owner_sell_reason: string | null;

  /** @format date-time */
  current_deployed_at: string | null;
}

export enum ListingEditRecordHistoryStatus {
  Active = "active",
  Inactive = "inactive",
}

export interface ListingEditRecordHistoryEntity {
  modify_detail: ListingRecordModifyingEntity;
  status: ListingEditRecordHistoryStatus;
  id: string;
  record_id: string;
  comment: string;
  author: string;

  /** @format date-time */
  updated_at: string;
}

export interface ListingEditRecordEntity {
  status: ListingEditRecordStatus;
  listing: ListingDetail;
  listing_edit_record_history: ListingEditRecordHistoryEntity[];
  id: string;

  /** @format date-time */
  updated_at: string;
  listing_id: string;
  reviewer: string | null;
}

export interface ListingEditorReviewingDataEntity {
  recordStatus: ListingEditRecordStatus;
  editedListing: ListingDetail;
  originalListing: ListingDetail;
  recordHistory: ListingEditRecordHistoryEntity[];
}

export interface CreateListingRecordDto {
  listingId: string;
}

export interface CreateListingRecordEntity {
  recordId: string;
}

export interface PartialTypeClass {
  detail_guard_management_times?: GuardManagementTime;
  contract_type?: ContractTypeEnum;
  deployed_at?: string[];
  parking_detail?: ParkingDetailEntity[] | null;
  traffic_env_school?: TrafficEnvEntity[] | null;
  traffic_env_hospital?: TrafficEnvEntity[] | null;
  traffic_env_supermarket?: TrafficEnvEntity[] | null;
  traffic_env_traffic?: TrafficEnvEntity[] | null;
  traffic_env_recreation?: TrafficEnvEntity[] | null;
  traffic_env_corporate?: TrafficEnvEntity[] | null;
  country?: CountryEnum;
  status?: ListingStatusEnum;
  feature_tags?: string[] | null;
  picture_tags?: string[] | null;
  raw_image_urls?: string[] | null;
  watermark_image_urls?: string[] | null;
  youtube_video_ids?: string[] | null;
  detail_sorting_categories?: string[] | null;
  house_available_time?: string[];
  listing_state?: ListingState[];
  listing_image?: ListingImage[];
  listing_video?: ListingVideo[];
  listing_advanced_info?: ListingAdvancedInfoEntity | null;
  id?: string;
  sid?: string;
  legacyid?: string | null;
  buildingId?: string | null;
  address?: string | null;
  agent1_id?: string;
  agent2_id?: string | null;
  bath?: string | null;
  building_project?: string | null;
  city?: string | null;
  state?: string | null;
  common_space?: string | null;
  display_address?: string | null;
  district?: string | null;
  group?: string;
  order?: number;
  feature_content?: string | null;
  price?: number | null;
  dealPrice?: number | null;
  price_without_parking_slot?: number;
  parking_slot_total_price?: number;
  room?: string | null;
  balcony?: number | null;
  title?: string;
  detail_purpose?: string | null;
  detail_category?: string | null;
  detail_floor?: string | null;
  detail_age?: string | null;
  detail_total_area_size?: string | null;
  detail_main_area_size?: string | null;
  detail_other_area_size?: string | null;
  detail_amenities_area?: string | null;
  detail_lot_size?: string | null;
  detail_parking?: string | null;
  detail_balcony?: string | null;
  detail_amenities?: string | null;
  detail_management_fee?: string | null;
  detail_land_category?: string | null;
  detail_land_purpose?: string | null;
  detail_land_size?: string | null;
  detail_land_building?: boolean | null;
  detail_guard_management?: boolean | null;
  location_latitude?: string | null;
  location_longitude?: string | null;
  detail_parking_size?: string | null;

  /** @format date-time */
  created_at?: string;

  /** @format date-time */
  updated_at?: string;
  folderid?: string | null;

  /** @format date-time */
  sold_at?: string | null;

  /** @format date-time */
  close_at?: string | null;
  floor?: string | null;
  total_floor?: string | null;
  floor_note?: string | null;
  open_layout?: boolean;
  is_parking_space_in_amenities?: boolean;
  unit?: string | null;
  sec?: string | null;
  no?: string | null;
  month_of_building_completion?: number | null;
  year_of_building_completion?: number | null;
  date_of_completion?: string | null;
  display_building_project?: boolean;
  zip_code?: string | null;
  house_available_time_type?: string | null;
  mortgage_amount?: Decimal | null;
  owner_available_contact_time?: string | null;
  owner_email?: string | null;
  owner_has_mortgage?: boolean | null;
  owner_house_viewing_way?: string | null;
  owner_ideal_price?: Decimal | null;
  owner_line?: string | null;
  owner_name?: string | null;
  owner_phone?: string | null;
  owner_sell_reason?: string | null;

  /** @format date-time */
  current_deployed_at?: string | null;
  listing_hashtag?: ListingHashtagEntity[];
}

export interface SubmitListingRecordDto {
  record: PartialTypeClass;
  recordId: string;
  comment: string;
  readyReview: boolean;
}

export interface ApproveListingRecordDto {
  recordId: string;
}

export interface ForceInactiveListingDto {
  listingId: string;
}

export interface StartSortingListingEntity {
  recordId: string;
}

export interface OrderForSaveListingSortingResult {
  listingId: string;
  order: number;
}

export interface SaveListingSortingResultDto {
  orders: OrderForSaveListingSortingResult[];
  recordId: string;
  comment?: string;
}

export interface ApproveListingSortingDto {
  recordId: string;
}

export interface JobEntity {
  id: string;
  sid: string;
  title: string | null;
  jd: string;
  fulltime: boolean;
  org: string;
  location: string | null;
  city: string | null;
  archived: boolean;

  /** @format date-time */
  created_at: string | null;

  /** @format date-time */
  expire_at: string | null;
  qualification: string | null;
  offer: string | null;
}

export interface HomePageEntity {
  listings: ListingDetail[];
  agents: Agent[];
  jobs: JobEntity[];
  medias: MediaEntity[];
}

export type CreateUserDto = object;

export interface CreateUserRoleDto {
  id: string;
  role: string;
  user_id: string;
}

export interface UserRole {
  id: string;
  role: string;
  user_id: string;
}

export interface User {
  id: string;
  name: string;
  email: string;

  /** @format date-time */
  email_verified: string;
  image: string;
  role: string;
  user_role: UserRole[];
}

export interface RegisterUserDto {
  email: string;
}

export type UpdateUserDto = object;

export interface CreateReferralSourceDto {
  id: string;
  display_name: string;
  tag: string;
  icon: string;
}

export interface UpdateReferralSourceDto {
  id?: string;
  display_name?: string;
  tag?: string;
  icon?: string;
}

export type CreateReferralApplicationDto = object;

export interface ReferralApplication {
  id: string;
  name: string;
  type: string;
}

export type UpdateReferralApplicationDto = object;

export interface CreateReferralLinkDto {
  referral_source_id: string;
  update_time: Decimal;
  listing_id: string;
  agent_id: string;
  referral_application_id: string;
  id: string;
  name: string;
}

export interface ReferralLink {
  referral_source_id: string;
  update_time: Decimal;
  id: string;
  name: string;
  listing_id: string;
  agent_id: string;
  referral_application_id: string;
  listing: ListingDetail;
  agent: Agent;
  application: ReferralApplication;
}

export interface AssignAgentDto {
  visitorId: string;
  agentId: string;
}

export interface ContentProvider {
  type: string;
}

export interface Message {
  type: string;
  id: string;
  contentProvider: ContentProvider;
  text: string;
  stickerId: string;
}

export interface DeliveryContext {
  isRedelivery: boolean;
}

export interface Source {
  type: string;
  userId: string;
}

export interface Event {
  type: string;
  message: Message;
  webhookEventId: string;
  deliveryContext: DeliveryContext;
  timestamp: number;
  source: Source;
  replyToken: string;
  mode: string;
}

export interface LineWebhookDTO {
  destination: string;
  events: Event[];
}

export interface DirectMessageDto {
  token: string;
  bot: boolean;
  channel_id: string;
  message_id: string;
  timestamp: string;
  user_id: string;
  user_name: string;
  text: string;
  siteUrl: string;
}

export interface UserRoleTypeEntity {
  role:
    | "user.permission.read"
    | "user.permission.write"
    | "visitor.quene.read"
    | "visitor.all.read"
    | "listings.list.read"
    | "listings.list.write"
    | "listings.review.write"
    | "listings.review.read"
    | "listings.review.create"
    | "building.list.read"
    | "building.list.create"
    | "building.list.write"
    | "building.review.read"
    | "building.review.write";
  id: string;
}

export interface Permission {
  organization_id: string;
  email: string;
  id_token: string;
  google_oauth: boolean;
  facebook_oauth: boolean;
  crm_id: string;
  facebook_id: string | null;
  facebook_token: string | null;
  instagram_id: string | null;
  instagram_token: string | null;
  role: string;
  id: string;
  active: boolean;
  name: string;
  image: string;
  crm_authToken: string;
  user_role: UserRole[];
  crm_name: string | null;
}

export interface UpdatePermissionDto {
  role?: string[];
}

export interface FindPrivateRoomDto {
  userId: string;
  targetId: string;
}

export interface PrivateRoomEntity {
  id: string;
  user_id: string;
  user_name: string;
  receiver_id: string;
  receiver_name: string;
  room_id: string;
  backyard_user_id: string;
}

export interface SearchPrivateMessageHistoryDto {
  roomId: string;
  latest?: string;
  offset: number;
  count: number;
  unreads: boolean;
}

export interface UserInPrivateMessageEntity {
  _id: string;
  username: string;
}

export interface PrivateMessageEntity {
  _id: string;
  t?: string;
  rid: string;
  msg: string;
  ts: string;
  u: UserInPrivateMessageEntity;
  groupable?: boolean;
  _updatedAt: string;
}

export interface PrivateMessageHistoryEntity {
  messages: PrivateMessageEntity[];
  success: boolean;
}

export interface UserInRoomInfoEntity {
  _id: string;
  username: string;
}

export interface VisitorInRoomInfoEntity {
  status?: CRMAgentOnlineStatus;
  _id: string;
  username: string;
  token: string;
  lastMessageTs: string;
}

export interface RoomServedByEntity {
  ts: string;
  username: string;
  _id: string;
}

export interface RoomInfoContentEntity {
  _id: string;
  name: string;
  fname: string;
  t: string;
  msgs: number;
  usersCount: number;
  u?: UserInRoomInfoEntity;
  v?: VisitorInRoomInfoEntity;
  customFields: object;
  broadcast: boolean;
  encrypted: boolean;
  ts: string;
  ro: boolean;
  default: boolean;
  sysMes: boolean;
  _updatedAt: string;
  unread: number;
  servedBy?: RoomServedByEntity;
}

export interface RoomInfoEntity {
  room: RoomInfoContentEntity;
  success: boolean;
}

export interface RocketChatUserService {
  password: object;
  email: object;
  resume: object;
  cloud: object;
}

export interface RocketChatUserEmail {
  address: string;
  verified: boolean;
}

export interface RocketChatUserInfoContent {
  services: RocketChatUserService[];
  emails: RocketChatUserEmail[];
  roles: string[];
  statusLivechat: CRMAgentLiveChatStatus;
  onlineStatus: CRMAgentOnlineStatus;
  _id: string;
  createdAt: string;
  type: string;
  status: string;
  active: boolean;
  name: string;
  lastLogin: string;
  statusConnection: string;
  utcOffset: number;
  username: string;
  statusText: string;
  requirePasswordChange: boolean;
  canViewAllInfo: boolean;
  avatarUrl?: string;
}

export interface RocketChatUserInfoEntity {
  user: RocketChatUserInfoContent;
  success: boolean;
}

export interface UserInJoinedPrivateRoomsLastMessage {
  _id: string;
  username: string;
  name: string;
}

export interface MDValueInJoinedPrivateRooms {
  type: string;
  value: string;
}

export interface MDInJoinedPrivateRooms {
  value: MDValueInJoinedPrivateRooms[];
  type: string;
}

export interface JoinedPrivateRoomsLastMessage {
  urls: string[];
  mentions: string[];
  channels: string[];
  msg: string;
  rid: string;
  ts: string;
  u: UserInJoinedPrivateRoomsLastMessage;
  _id: string;
  _updatedAt: string;
  md: MDInJoinedPrivateRooms[];
}

export interface JoinedPrivateRoomListEntity {
  uids: string[];
  usernames: string[];
  _USERNAMES: string[];
  default?: boolean;
  lastMessage: JoinedPrivateRoomsLastMessage;
  lm: string;
  msgs: number;
  ro: boolean;
  sysMes: boolean;
  t: string;
  ts: string;
  usersCount: number;
  _id: string;
  _updatedAt: string;
  topic: string;
  username: string;
  avatar: string;
  unreads: number;
}

export interface JoinedPrivateRoomListResultEntity {
  ims: JoinedPrivateRoomListEntity[];
  offset: number;
  count: number;
  total: number;
  success: boolean;
}

export interface FindPlacesAutocompleteDto {
  country?: CountryEnum;
  address: string;
  uuid: string;
  language: string;
}

export interface FindPlacesAutocompletePredictionTerm {
  value: string;
  offset: number;
}

export interface FindPlacesAutocompleteEntity {
  terms: FindPlacesAutocompletePredictionTerm[];
  description: string;
}

export enum TravelMode {
  Driving = "driving",
  Walking = "walking",
  Bicycling = "bicycling",
  Transit = "transit",
}

export interface FindDirectionTimeDto {
  mode?: TravelMode;
  lat: number;
  lng: number;
  destination: string;
}

export interface FindDirectionTimeEntity {
  type?: TravelMode;
  name: string;
  time: string;
}

export interface PipedriveMatchFilterDto {
  current: string[];
  previous: string[];
}

export interface PipedriveMetaDto {
  action: "added" | "deleted" | "merged" | "updated";
  object: "activityType" | "deal" | "note" | "organization" | "person" | "pipeline" | "product" | "stage" | "user";
  permitted_user_ids: number[];
  v: number;
  change_source: string;
  id: number;
  company_id: number;
  user_id: number;
  host: string;

  /** @format date-time */
  timestamp: string;

  /** @format date-time */
  timestamp_micro: string;
  trans_pending: boolean;
  is_bulk_update: boolean;
  pipedrive_service_name: object;
  matches_filters: PipedriveMatchFilterDto;
  webhook_id: number;
}

export interface PipedriveWebhookDto {
  v: number;
  matches_filters: PipedriveMatchFilterDto;
  meta: PipedriveMetaDto;
  retry: number;
  current: object;
  previous: object;
  event: string;
}

export interface PipedriveProductOwnerIdEntity {
  active_flag: boolean;
  email: string;
  has_pic: number;
  id: number;
  name: string;
  pic_hash: string | null;
  value: number;
}

export enum ProductCurrency {
  TWD = "TWD",
  CAD = "CAD",
  USD = "USD",
}

export interface PipedriveProductPriceEntity {
  currency: ProductCurrency;
  id: number;
  product_id: number;
  price: number;
  cost: number;
  overhead_cost: number;
}

export interface PipedriveProductEntity {
  "0b489848b896efd33823dee676fec2634d6ee57e": string | null;
  "0bfa31df44d070463c80d8455159ccbb24bccb58": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_admin_area_level_1": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_admin_area_level_2": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_country": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_formatted_address": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_locality": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_postal_code": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_route": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_street_number": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_sublocality": string | null;
  "5b5a2ca39201dd4785918fa038ff0aa3b8af0c95_subpremise": string | null;
  "7c6996cb67dc0ce7d597be411d1ac397b65ecdd0": string | null;
  "33d45170eac5d63cc677263f33537c2ab2c7aeb7": string | null;
  "192a327c95c1de5666515843ead65cdf81021b75": string | null;
  "643c89c938651822b60defe96c9257b8254d9553": string | null;
  "7992b18efef5dba17f1796ca0288c012617326eb": string | null;
  "7992b18efef5dba17f1796ca0288c012617326eb_currency": string | null;
  "908497428b7b0454f986c3aceadbb700f4a153a6": string | null;
  e965f460eb701c1855f2d1137a96434b1dd639c9: string | null;
  e48289fa22af5d305590099909afb91ef7031bdd: string | null;
  e601071dc53bf01d396d85a3d1f57f28bbea57c6: string | null;
  active_flag: boolean;
  add_time: string;
  category: boolean;
  code: string | null;
  description: string | null;
  files_count: number | null;
  first_char: string;
  followers_count: number;
  id: number;
  name: string;
  owner_id: PipedriveProductOwnerIdEntity;
  prices: PipedriveProductPriceEntity[];
  selectable: boolean;
  tax: number;
  unit: string | null;
  update_time: string;
  visible_to: string;
}

export interface PipedrivePaginationEntity {
  start: number;
  limit: number;
  more_items_in_collection: boolean;
  next_start: number;
}

export interface PipedriveGetAllProductsAdditionalDataEntity {
  pagination: PipedrivePaginationEntity;
}

export interface PipedriveProductRelatedObjectsEntity {
  user: object;
}

export interface PipedriveGetAllProductsEntity {
  success: boolean;
  data: PipedriveProductEntity[];
  additional_data: PipedriveGetAllProductsAdditionalDataEntity;
  related_objects: PipedriveProductRelatedObjectsEntity;
}

export interface PipedriveGetSpecificProductEntity {
  success: boolean;
  data: PipedriveProductEntity[];
  related_objects: PipedriveProductRelatedObjectsEntity;
}

export interface PipedriveUserInProductDealEntity {
  value: number;
  id: number;
  name: string;
  email: string;
  has_pic: number;
  pic_hash: string | null;
  active_flag: boolean;
}

export interface PipedrivePersonContactInfoEntity {
  label: string;
  value: string;
  primary: boolean;
}

export interface PipedrivePersonIdInProductDealEntity {
  value: number;
  active_flag: boolean;
  name: string;
  email: PipedrivePersonContactInfoEntity[];
  phone: PipedrivePersonContactInfoEntity[];
}

export interface PipedriveOrgIdInProductDealEntity {
  value: number;
  name: string;
  people_count: number;
  owner_id: number;
  address: string;
  active_flag: boolean;
  cc_email: string;
}

export interface PipedriveProductDealEntity {
  "72ca35aaf6d6373366193118020d8a6bb661571d": string;
  "298f07a97abb3f24d235d4ed51c91a017ed0d6dd": string;
  "718fc7d518a121c0359a63804a72d809fde02786": string;
  id: number;
  creator_user_id: PipedriveUserInProductDealEntity;
  user_id: PipedriveUserInProductDealEntity;
  person_id: PipedrivePersonIdInProductDealEntity;
  org_id: PipedriveOrgIdInProductDealEntity;
  stage_id: "PENDING" | "VISITED" | "SIGNED" | "BID" | "HANDOVER";
  title: string;
  value: number;
  currency: string;
  add_time: string;
  update_time: string;
  stage_change_time: string;
  active: boolean;
  deleted: boolean;
  status: object;
  probability: string | null;
  next_activity_date: string;
  next_activity_time: string;
  next_activity_id: number;
  last_activity_id: number | null;
  last_activity_date: string | null;
  lost_reason: string | null;
  visible_to: string;
  close_time: string | null;
  pipeline_id: number;
  won_time: string;
  first_won_time: string;
  lost_time: string;
  products_count: number;
  files_count: number;
  notes_count: number;
  followers_count: number;
  email_messages_count: number;
  activities_count: number;
  done_activities_count: number;
  undone_activities_count: number;
  participants_count: number;
  expected_close_date: string;
  last_incoming_mail_time: string;
  last_outgoing_mail_time: string;
  label: string;
  stage_order_nr: number;
  person_name: string;
  org_name: string;
  next_activity_subject: string;
  next_activity_type: string;
  next_activity_duration: string;
  next_activity_note: string;
  formatted_value: string;
  weighted_value: number;
  formatted_weighted_value: string;
  weighted_value_currency: string;
  rotten_time: string | null;
  owner_name: string;
  cc_email: string;
  org_hidden: boolean;
  person_hidden: boolean;
}

export interface PipedriveGetAllProductDealRelatedObjectsEntity {
  user: object;
  organization: object;
  person: object;
  stage: object;
  pipeline: object;
}

export interface PipedriveGetAllProductDealsAdditionalDataEntity {
  pagination: PipedrivePaginationEntity;
}

export interface PipedriveGetAllProductDealEntity {
  success: boolean;
  data: PipedriveProductDealEntity[];
  related_objects: PipedriveGetAllProductDealRelatedObjectsEntity;
  additional_data: PipedriveGetAllProductDealsAdditionalDataEntity;
}

export interface RelatedObjects {
  user: object;
}

export interface AdditionalData {
  products_quantity_total: number;
  products_sum_total: number;
  variations_enabled: boolean;
  products_quantity_total_formatted: string;
  products_sum_total_formatted: string;
  pagination: PipedrivePaginationEntity;
}

export interface PipedriveDealRelatedProductsEntity {
  success: boolean;
  data: object;
  related_objects: RelatedObjects;
  additional_data: AdditionalData;
}

export interface TimeDto {
  /** @format date-time */
  from: string;

  /** @format date-time */
  until: string;
}

export interface SearchProductDto {
  deployed_at?: TimeDto;
  agent_name?: string[];
  locality?: string[];
  is_external_product?: boolean;
}

export enum ProductStatus {
  Active = "active",
  Inactive = "inactive",
  Sold = "sold",
}

export enum ProductDealType {
  IN_HOUSE = "IN_HOUSE",
  MATCH_BUYER = "MATCH_BUYER",
  MATCH_SELLER = "MATCH_SELLER",
}

export enum ProductDealStatus {
  Open = "open",
  Won = "won",
  Lost = "lost",
}

export interface ProductDealsListingSnapshotEntity {
  shot_at: string;
  listing: ListingDetail;
}

export interface ProductDealsEntity {
  weighted_value_currency: ProductCurrency;
  deal_type: ProductDealType;
  status: ProductDealStatus;
  id: string;
  weighted_value: number;
  pipedrive_id: number;
  agent_name: string;
  customer_name: string;
  seller_commission_rate: Decimal;
  buyer_commission_rate: Decimal;
  seller_service_fee_rate: Decimal;
  buyer_service_fee_rate: Decimal;
  match_listing_brand: string | null;
  match_listing_ref_url: string | null;
  match_listing_brand_agent_name: string | null;
  match_listing_brand_agent_phone: string | null;
  match_listing_brand_agent_line: string | null;
  listing_snapshot?: ProductDealsListingSnapshotEntity[] | null;

  /** @format date-time */
  dealt_at: string | null;

  /** @format date-time */
  won_at: string | null;

  /** @format date-time */
  first_won_at: string | null;
}

export interface ProductIncludeProductDealsEntity {
  status: ProductStatus;
  currency: ProductCurrency;
  product_deals: ProductDealsEntity[];

  /** @format date-time */
  deployed_at?: string;
  id: string;
  pipedrive_id: number;
  is_helped_by_operation: boolean;
  name: string;
  agent_name: string;
  price: number;
  reference_url: string;
  locality: string;
  is_external_product: boolean;
  listing_sid: string | null;
  listing?: ListingDetail;

  /** @format date-time */
  product_expired_at: string | null;
}

export interface SearchProductDealsDto {
  deal_type?: ProductDealType[];
  dealt_at?: TimeDto;
  agent_name?: string[];
}

export interface ProductEntity {
  status: ProductStatus;
  currency: ProductCurrency;
  id: string;
  pipedrive_id: number;
  is_helped_by_operation: boolean;
  name: string;
  agent_name: string;
  price: number;
  reference_url: string;
  locality: string;
  is_external_product: boolean;
  listing_sid: string | null;
  listing?: ListingDetail;

  /** @format date-time */
  product_expired_at: string | null;
}

export interface ProductDealsIncludeProductEntity {
  weighted_value_currency: ProductCurrency;
  deal_type: ProductDealType;
  status: ProductDealStatus;
  id: string;
  weighted_value: number;
  pipedrive_id: number;
  agent_name: string;
  customer_name: string;
  seller_commission_rate: Decimal;
  buyer_commission_rate: Decimal;
  seller_service_fee_rate: Decimal;
  buyer_service_fee_rate: Decimal;
  match_listing_brand: string | null;
  match_listing_ref_url: string | null;
  match_listing_brand_agent_name: string | null;
  match_listing_brand_agent_phone: string | null;
  match_listing_brand_agent_line: string | null;
  listing_snapshot?: ProductDealsListingSnapshotEntity[] | null;

  /** @format date-time */
  dealt_at: string | null;

  /** @format date-time */
  won_at: string | null;

  /** @format date-time */
  first_won_at: string | null;
  product: ProductEntity[];
}

export interface CreateCountryDto {
  id: string;
  country_code: string;
  english_name: string;
  chinese_name: string;
  active: boolean;
}

export interface Country {
  id: string;
  country_code: string | null;
  english_name: string | null;
  chinese_name: string | null;
  active: boolean | null;
}

export interface UpdateCountryDto {
  id?: string;
  country_code?: string;
  english_name?: string;
  chinese_name?: string;
  active?: boolean;
}

export enum StaticOptionCategory {
  CONFERENCE_LOCATION = "CONFERENCE_LOCATION",
  BANNER = "BANNER",
}

export interface CreateStaticOptionDto {
  category: StaticOptionCategory;
  value: string;
  label: string;
  description: string | null;
  active: boolean;
  order: number;
}

export interface StaticOptionEntity {
  id: string;
  value: string;
  label: string;
  description: string | null;
  active: boolean;
  order: number;

  /** @format date-time */
  inactivated_at: string | null;
}

export interface SubLocationData {
  en: string;
  zh_tw: string;
  key: string;
  acceptanceSearchKeywords: string[];
}

export interface LocationData {
  en: string;
  zh_tw: string;
  key: string;
  acceptanceSearchKeywords: string[];
  cities: SubLocationData[];
}

export interface LocationDataEntity {
  TW: LocationData[];
  US: LocationData[];
  CA: LocationData[];
}

export interface PipedriveLocationEntity {
  key:
    | "taipei"
    | "new_taipei"
    | "keelung"
    | "taoyuan"
    | "hsinchu"
    | "miaoli"
    | "taichung"
    | "changhua"
    | "nantou"
    | "yunlin"
    | "chiayi"
    | "tainan"
    | "kaohsiung"
    | "pingtung"
    | "yilan"
    | "hualien"
    | "taitung"
    | "taiwan_outside"
    | "abroad";
  id: number;
  label: string;
}

export interface PipedriveLocationOptionDataEntity {
  data: PipedriveLocationEntity[];
}

export interface CheckStaticOptionValueDuplicatedInSpecificCategoryEntity {
  duplicated: boolean;
}

export enum BannerSettingEnum {
  HOMEPAGE = "HOMEPAGE",
  ABROAD = "ABROAD",
  SELL = "SELL",
}

export interface UpdatePageBannerDto {
  page: BannerSettingEnum;

  /** @format binary */
  file: File;
}

export interface UpdateStaticOptionDto {
  value: string;
  label: string;
  description: string | null;
  active: boolean;
  order: number;
}

export enum BuildingCountry {
  TW = "TW",
  US = "US",
  CA = "CA",
}

export enum BuildingStateTag {
  NEW = "NEW",
  OPEN_HOUSE = "OPEN_HOUSE",
  EXCLUSIVE = "EXCLUSIVE",
  PRESALE = "PRESALE",
}

export interface BuildingStateEntity {
  state: BuildingStateTag;
  id: string;

  /** @format date-time */
  expiredAt: string;
  buildingId: string;

  /** @format date-time */
  createdAt: string;
}

export interface BuildingParkingLotEntity {
  id: string;
  type: string;
  minimumPrice: number;
  maximumPrice: number;
  buildingId: string;

  /** @format date-time */
  createdAt: string;
}

export interface BuildingFloorPlan {
  id: string;
  buildingId: string;
  planType: string;
  price: number | null;
  minimumAreaSize: number | null;
  maximumAreaSize: number | null;

  /** @format date-time */
  createdAt: string;
}

export enum TrafficEnvCategory {
  SHOPPING_DISTRICT = "SHOPPING_DISTRICT",
  SCHOOL = "SCHOOL",
  COMMUNICATION = "COMMUNICATION",
  HOSPITAL = "HOSPITAL",
  PARK = "PARK",
  OFFICE = "OFFICE",
}

export interface BuildingTrafficEnv {
  category: TrafficEnvCategory;
  id: string;
  buildingId: string;
  name: string;
  distance: number;
  gType: string;
  type: string;
  lat: number;
  lng: number;
  walkTime: string;
  driveTime: string;
  active: boolean;

  /** @format date-time */
  createdAt: string;
}

export enum BuildingListingStatus {
  RAW = "RAW",
  STANDARD = "STANDARD",
  ELEGANT = "ELEGANT",
}

export interface BuildingInternalInformation {
  listingStatus: BuildingListingStatus;
  id: string;
  buildingId: string;
  displayName: boolean;
  name: string;
  baseAddress: string;
  baseSize: number;
  totalParkingLots: number | null;
  parkingLotRatio: string;
  guardianMethod: string;
  hoaFee: number | null;
  displayArchitectureDesigner: boolean;
  architectureDesigner: string | null;
  displayConstructionCompany: boolean;
  constructionCompany: string | null;
  displayLandscapeDesigner: boolean;
  landscapeDesigner: string | null;
  displayPublicAreaDesigner: boolean;
  publicAreaDesigner: string | null;
  displayLightingDesigner: boolean;
  lightingDesigner: string | null;
  displayInteriorDesigner: boolean;
  interiorDesigner: string | null;
}

export interface EditBuildingDetailEntity {
  status: ActivityStatus;
  country: BuildingCountry;
  id: string;
  sid: string;
  order: number;
  agentForDisplayId: string;
  agentInChargeId: string;
  longitude: number;
  latitude: number;
  state: string | null;
  city: string;
  address: string;
  displayAddress: string;
  zipCode: string;
  adTitle: string;
  maximumPrice: number;
  minimumPrice: number;
  maximumPricePerUnit: number;
  minimumPricePerUnit: number;
  maximumPublicAreaRatio: number;
  minimumPublicAreaRatio: number;
  type: string;
  registrationPurpose: string;
  displayStartAt: boolean;
  startYear: number;
  startMonth: number;
  completeYear: number;
  completeMonth: number;
  floorOnGround: number;
  floorUnderGround: number | null;
  totalUnit: number;
  displayStructureEngineering: boolean;
  structureEngineering: string;
  displayBuilder: boolean;
  builder: string;
  featureTags: string[];
  featureDescription: string;
  amenities: string[];
  comment: string | null;
  buildingState: BuildingStateEntity[];
  buildingParkingLot: BuildingParkingLotEntity[];
  buildingFloorPlan: BuildingFloorPlan[];
  buildingTrafficEnv: BuildingTrafficEnv[];
  buildingInternalInformation: BuildingInternalInformation | null;
  buildingImage: BuildingImage[];
  buildingVideo: BuildingVideo[];
  agentForDisplay: object | null;
  agentInCharge: object | null;
}

export interface ListingRelatedBuildingInformation {
  id: string;
  adTitle: string;
}

export interface BuildingFloorPlanForShowcaseEntity {
  id: string;
  planType: string;
}

export interface BuildingShowcaseEntity {
  country: BuildingCountry;
  buildingFloorPlan: BuildingFloorPlanForShowcaseEntity[];
  id: string;
  sid: string;
  buildingImage: BuildingImage[];
  buildingState: BuildingStateEntity[];
  city: string;
  state: string | null;
  adTitle: string;
  minimumPrice: number;
  completeYear: number;
  completeMonth: number;
}

export interface AgentForBuildingToDisplay {
  pictures?: string[] | null;
  agent_service_location?: AgentServiceLocation[] | null;
  id: string;
  name: string | null;
  chinese_name: string | null;
  english_name: string | null;
  bio: string | null;
  email: string;
  contact_phone: string | null;
  contact_fb: string | null;
  contact_ig: string | null;
  contact_line: string | null;
  license: string | null;
  active: boolean | null;
  sid: string;
  group: string;
  order: number;
  qrcode_picture_line: string | null;
}

export interface ListingForBuildingShowcaseEntity {
  listing_state: ListingState[];
  listing_image: ListingImage[];
  id: string;
  sid: string;
  title: string;
  floor: string | null;
  floor_note: string | null;
  room: string | null;
  common_space: string | null;
  bath: string | null;
  balcony: number | null;
  detail_main_area_size: string | null;
  detail_total_area_size: string | null;
  dealPrice: number | null;
  price: number | null;
}

export interface BuildingShowcaseDetailsEntity {
  status: ActivityStatus;
  country: BuildingCountry;
  buildingFloorPlan: BuildingFloorPlanForShowcaseEntity[];
  agentForDisplay: AgentForBuildingToDisplay;
  listing: ListingForBuildingShowcaseEntity[];
  id: string;
  sid: string;
  latitude: number;
  longitude: number;
  buildingState: BuildingStateEntity[];
  startYear: number;
  startMonth: number;
  displayStartAt: boolean;
  completeYear: number;
  completeMonth: number;
  buildingImage: BuildingImage[];
  buildingVideo: BuildingVideo[];
  minimumPrice: number;
  adTitle: string;
  state: string | null;
  city: string;
  displayAddress: string;
  registrationPurpose: string;
  type: string;
  floorOnGround: number;
  floorUnderGround: number | null;
  totalUnit: number;
  builder: string;
  displayBuilder: boolean;
  structureEngineering: string;
  displayStructureEngineering: boolean;
  minimumPublicAreaRatio: number;
  maximumPublicAreaRatio: number;
  amenities: string[];
  featureTags: string[];
  featureDescription: string;
  buildingTrafficEnv: BuildingTrafficEnv[];
}

export interface CreateTaiwanBuildingDto {
  address: string;
}

export interface CreateUSBuildingDto {
  state: string;
  city: string;
  address: string;
}

export interface CreateCanadaBuildingDto {
  state: string;
  city: string;
  address: string;
}

export interface CreateBuildingEditRecordDto {
  buildingId: string;
}

export interface CreateBuildingEditRecordEntity {
  recordId: string;
}

export interface Before {
  status: ActivityStatus;
  country: BuildingCountry;
  id: string;
  sid: string;
  order: number;
  agentForDisplayId: string;
  agentInChargeId: string;
  longitude: number;
  latitude: number;
  state: string | null;
  city: string;
  address: string;
  displayAddress: string;
  zipCode: string;
  adTitle: string;
  maximumPrice: number;
  minimumPrice: number;
  maximumPricePerUnit: number;
  minimumPricePerUnit: number;
  maximumPublicAreaRatio: number;
  minimumPublicAreaRatio: number;
  type: string;
  registrationPurpose: string;
  displayStartAt: boolean;
  startYear: number;
  startMonth: number;
  completeYear: number;
  completeMonth: number;
  floorOnGround: number;
  floorUnderGround: number | null;
  totalUnit: number;
  displayStructureEngineering: boolean;
  structureEngineering: string;
  displayBuilder: boolean;
  builder: string;
  featureTags: string[];
  featureDescription: string;
  amenities: string[];
  comment: string | null;
  buildingState: BuildingStateEntity[];
  buildingParkingLot: BuildingParkingLotEntity[];
  buildingFloorPlan: BuildingFloorPlan[];
  buildingTrafficEnv: BuildingTrafficEnv[];
  buildingInternalInformation: BuildingInternalInformation | null;
  buildingImage: BuildingImage[];
  buildingVideo: BuildingVideo[];
}

export interface BuildingParkingLotForEdit {
  type: string;
  minimumPrice: number;
  maximumPrice: number;
}

export interface BuildingFloorPlanForEdit {
  planType: string;
  price: number | null;
  minimumAreaSize: number | null;
  maximumAreaSize: number | null;
}

export interface BuildingTrafficEnvForEdit {
  category: TrafficEnvCategory;
  name: string;
  distance: number;
  gType: string;
  type: string;
  lat: number;
  lng: number;
  walkTime: string;
  driveTime: string;
  active: boolean;

  /** @format date-time */
  createdAt: string;
}

export interface BuildingInternalInformationForEdit {
  listingStatus: BuildingListingStatus;
  displayName: boolean;
  name: string;
  baseAddress: string;
  baseSize: number;
  totalParkingLots: number | null;
  parkingLotRatio: string;
  guardianMethod: string;
  hoaFee: number | null;
  displayArchitectureDesigner: boolean;
  architectureDesigner: string | null;
  displayConstructionCompany: boolean;
  constructionCompany: string | null;
  displayLandscapeDesigner: boolean;
  landscapeDesigner: string | null;
  displayPublicAreaDesigner: boolean;
  publicAreaDesigner: string | null;
  displayLightingDesigner: boolean;
  lightingDesigner: string | null;
  displayInteriorDesigner: boolean;
  interiorDesigner: string | null;
}

export interface BuildingImageForEdit {
  status: ActivityStatus;
  id: string;
  buildingId: string;
  watermark: string | null;
  googleDriveIdForWatermark: string | null;
  original: string | null;
  googleDriveIdForOriginal: string | null;
  order: number;
  tag: string | null;
}

export interface BuildingVideoForEdit {
  source: VideoSource;
  status: ActivityStatus;
  id: string;
  videoUrl: string;
  order: number;
  buildingId: string;
}

export interface BuildingStateForEdit {
  state: BuildingStateTag;

  /** @format date-time */
  expiredAt: string;
}

export interface After {
  status?: ActivityStatus;
  country?: BuildingCountry;
  buildingParkingLot?: BuildingParkingLotForEdit[];
  buildingFloorPlan?: BuildingFloorPlanForEdit[];
  buildingTrafficEnv?: BuildingTrafficEnvForEdit[];
  buildingInternalInformation?: BuildingInternalInformationForEdit | null;
  buildingImage?: BuildingImageForEdit[];
  buildingVideo?: BuildingVideoForEdit[];
  buildingState?: BuildingStateForEdit[];
  id?: string;
  sid?: string;
  order?: number;
  agentForDisplayId?: string;
  agentInChargeId?: string;
  longitude?: number;
  latitude?: number;
  state?: string | null;
  city?: string;
  address?: string;
  displayAddress?: string;
  zipCode?: string;
  adTitle?: string;
  maximumPrice?: number;
  minimumPrice?: number;
  maximumPricePerUnit?: number;
  minimumPricePerUnit?: number;
  maximumPublicAreaRatio?: number;
  minimumPublicAreaRatio?: number;
  type?: string;
  registrationPurpose?: string;
  displayStartAt?: boolean;
  startYear?: number;
  startMonth?: number;
  completeYear?: number;
  completeMonth?: number;
  floorOnGround?: number;
  floorUnderGround?: number | null;
  totalUnit?: number;
  displayStructureEngineering?: boolean;
  structureEngineering?: string;
  displayBuilder?: boolean;
  builder?: string;
  featureTags?: string[];
  featureDescription?: string;
  amenities?: string[];
  comment?: string | null;
}

export interface BuildingEditingRecordEntity {
  before: Before;
  after: After;
}

export interface CreateRecordHistoryDto {
  status?: ActivityStatus;
  country?: BuildingCountry;
  note: string | null;
  id?: string;
  sid?: string;
  order?: number;
  agentForDisplayId?: string;
  agentInChargeId?: string;
  longitude?: number;
  latitude?: number;
  state?: string | null;
  city?: string;
  address?: string;
  displayAddress?: string;
  zipCode?: string;
  adTitle?: string;
  maximumPrice?: number;
  minimumPrice?: number;
  maximumPricePerUnit?: number;
  minimumPricePerUnit?: number;
  maximumPublicAreaRatio?: number;
  minimumPublicAreaRatio?: number;
  type?: string;
  registrationPurpose?: string;
  displayStartAt?: boolean;
  startYear?: number;
  startMonth?: number;
  completeYear?: number;
  completeMonth?: number;
  floorOnGround?: number;
  floorUnderGround?: number | null;
  totalUnit?: number;
  displayStructureEngineering?: boolean;
  structureEngineering?: string;
  displayBuilder?: boolean;
  builder?: string;
  featureTags?: string[];
  featureDescription?: string;
  amenities?: string[];
  comment?: string | null;
  buildingParkingLot?: BuildingParkingLotForEdit[];
  buildingFloorPlan?: BuildingFloorPlanForEdit[];
  buildingTrafficEnv?: BuildingTrafficEnvForEdit[];
  buildingInternalInformation?: BuildingInternalInformationForEdit | null;
  buildingImage?: BuildingImageForEdit[];
  buildingVideo?: BuildingVideoForEdit[];
  buildingState?: BuildingStateForEdit[];
}

export interface BuildingEditRecordHistory {
  status: ActivityStatus;
  id: string;
  recordId: string;
  modifyDetail: object;
  comment: string;
  author: string;

  /** @format date-time */
  updatedAt: string;
}

export interface StartSortingBuildingEntity {
  recordId: string;
}

export interface OrderForSaveBuildingSortingResult {
  buildingId: string;
  order: number;
}

export interface SaveBuildingSortingResultDto {
  orders: OrderForSaveBuildingSortingResult[];
  recordId: string;
  comment?: string;
}

export interface ApproveBuildingSortingDto {
  recordId: string;
}

export enum PostCategory {
  MEDIA = "MEDIA",
  TREND = "TREND",
}

export interface PublishedPostEntity {
  category: PostCategory;
  id: string;
  uuid: string;
  subCategory: string;
  title: string;
  tag: string;
  description: string;
  coverImage: string;
  foreignUrl: string | null;

  /** @format date-time */
  lastModifiedAt: string;
  active: boolean;
  order: number;
}

export interface GetPublishedPostResponseEntity {
  items: PublishedPostEntity[];
}

export interface PublishedPostDetailEntity {
  category: PostCategory;
  relatedListings: ListingListEntity[];
  id: string;
  uuid: string;
  subCategory: string;
  title: string;
  tag: string;
  description: string;
  coverImage: string;
  foreignUrl: string | null;
  body: string;

  /** @format date-time */
  lastModifiedAt: string;
  active: boolean;
  order: number;
}

export interface ModifyingVersionPostEntity {
  category: PostCategory;
  id: string;
  uuid: string;
  subCategory: string;
  title: string;
  tag: string;
  description: string;
  coverImage: string;
  foreignUrl: string | null;
  body: string;

  /** @format date-time */
  lastModifiedAt: string;
}

export interface PostDetailEntity {
  category: PostCategory;
  modifyingVersion: ModifyingVersionPostEntity | null;
  modifiedBy: string | null;
  id: string;
  uuid: string;
  subCategory: string;
  title: string;
  tag: string;
  description: string;
  coverImage: string;
  foreignUrl: string | null;
  body: string;

  /** @format date-time */
  lastModifiedAt: string;
  active: boolean;
  order: number;
}

export interface PostItemEntity {
  category: PostCategory;
  id: string;
  uuid: string;
  title: string;
  subCategory: string;
  tag: string;
  coverImage: string;

  /** @format date-time */
  lastModifiedAt: string;
  beModifying: boolean;
  active: boolean;
  order: number;
  relatedListings: ListingListEntity[];
  modifyingVersion: ModifyingVersionPostEntity | null;
}

export interface GetPostsEntity {
  items: PostItemEntity[];
}

export interface CreatePostDto {
  category: PostCategory;
  foreignUrl?: string | null;
  uuid: string;
  subCategory: string;
  title: string;
  tag: string;
  description: string;
  coverImage: string;
  body: string;
}

export interface PostEntity {
  category: PostCategory;
  id: string;
  uuid: string;
  subCategory: string;
  title: string;
  tag: string;
  description: string;
  coverImage: string;
  foreignUrl: string | null;
  body: string;

  /** @format date-time */
  lastModifiedAt: string;
  active: boolean;
  order: number;
}

export interface IssuePostModifyingEntity {
  modifyId: string;
}

export interface UpdatePostDto {
  uuid?: string;
  subCategory?: string;
  title?: string;
  tag?: string;
  description?: string;
  coverImage?: string;
  body?: string;
  foreignUrl?: string | null;
}

export type UpdateOrderItemDto = object;

export interface ConnectListingDto {
  postId: string;
  listingIds: string[];
}

export enum PageOfUserStory {
  HOME = "HOME",
  SELL = "SELL",
  ABROAD = "ABROAD",
}

export interface UserStoryHighlightEntity {
  label: string;
  value: string;
}

export interface UserStoryShowcaseEntity {
  page: PageOfUserStory;
  id: string;
  location: string | null;
  highlights: UserStoryHighlightEntity[];
  content: string;
  buyer: string;
  order: number;
  photoUrl: string;
}

export interface ModifyUserStoryDto {
  buyer: string;
  highlights: UserStoryHighlightEntity[];
  content: string;
  location: string | null;
  page: object;
  photoUrl: string;
}

export interface UserStoryEntity {
  page: PageOfUserStory;
  id: string;
  location: string | null;
  highlights: UserStoryHighlightEntity[];
  content: string;
  buyer: string;
  modifying: ModifyUserStoryDto | null;
  order: number;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  photoUrl: string;
}

export interface CreateUserStoryDto {
  page: PageOfUserStory;
  location: string | null;
  highlights: UserStoryHighlightEntity[];
  content: string;
  buyer: string;
  photoUrl: string;
}

export interface CloneUserStoryDto {
  page: PageOfUserStory;
  id: string;
}

export interface UpdateUserStoryDto {
  buyer?: string;
  highlights?: UserStoryHighlightEntity[];
  content?: string;
  location?: string | null;
  page?: object;
  photoUrl?: string;
}

export interface UpdateUserStoryOrderDto {
  id: string;
  order: number;
}

export interface UpdateUserStoryOrderRequestDto {
  data: UpdateUserStoryOrderDto[];
}

export interface SubscribeEdmDto {
  name?: string;
  email: string;
}

export interface UTM {
  userAgent?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

export interface SendEmailContactMailDto {
  type: "CONTACT";
  name: string;
  phone: string;
  email?: string;
  whereDoYouKnowUsFrom: string;
  note?: string;
  utm?: UTM;
}

export interface SendEmailInterestedBuildingDto {
  type: "INTERESTED_BUILDING";
  name: string;
  phone: string;
  email: string;
  interestedUnitType: string;
  buildingId: string;
  whereDoYouKnowUsFrom: string;
  utm?: UTM;
}

export interface SendEmailSellMailDto {
  type: "SELL";
  name: string;
  email?: string;
  phone: string;
  address: string;
  whereDoYouKnowUsFrom: string;
  note?: string;
  utm?: UTM;
}

export interface SendEmailBuyMailDto {
  type: "BUY";
  name: string;
  phone: string;
  listing_id: string;
  whereDoYouKnowUsFrom: string;
  utm?: UTM;
}

export interface SendEmailBuyHouseConsultantMailDto {
  type: "BUY_HOUSE";
  name: string;
  phone: string;
  email: string;
  comment: string;
  whereDoYouKnowUsFrom: string;
  utm?: UTM;
}

export interface SendEmailSpecificAgentMailDto {
  type: "SPECIFIC_AGENT";
  agent_id: string;
  name: string;
  phone: string;
  email?: string;
  whereDoYouKnowUsFrom: string;
  note?: string;
  utm?: UTM;
}

export interface SendEmailSpecificTimeMailDto {
  type: "SPECIFIC_TIME";
  name: string;
  contactTime: string;
  listing_id: string;
  phone: string;
  email: string;
  whereDoYouKnowUsFrom: string;
  utm?: UTM;
}

export interface SendEmailOverseaInvestmentDto {
  type: "OVERSEA_INVESTMENT";
  name: string;
  phone: string;
  email: string;
  interestArea: string;
  whereDoYouKnowUsFrom: string;
  utm?: UTM;
}

export enum AbroadConsultantPropose {
  INVESTMENT = "INVESTMENT",
  PERSONAL = "PERSONAL",
}

export enum InterestTopic {
  SCHOOL_DISTRICT = "SCHOOL_DISTRICT",
  BUYING_PROCESS = "BUYING_PROCESS",
  MARKETING_TREND = "MARKETING_TREND",
  LOAN_CONSULTANT = "LOAN_CONSULTANT",
  LEGACY_INHERITANCE = "LEGACY_INHERITANCE",
  TAX_PLANNING = "TAX_PLANNING",
  RENTAL_CONSULTANT = "RENTAL_CONSULTANT",
}

export interface SendEmailAbroadDto {
  type: "ABROAD";
  propose: AbroadConsultantPropose;
  budgetCurrency: "USD" | "TWD";
  haveIdentity: "true" | "false";
  haveExperienced: "true" | "false";
  interestTopic: InterestTopic;
  name: string;
  phone: string;
  email: string;
  comment?: string;
  budget: string;
  interestCity: string[];
  desireConferenceLocation: string;
  whereDoYouKnowUsFrom: string;
  dDay: string;
  utm?: UTM;
}

export interface SendEmailPersonalConsultationDto {
  type: "PERSONAL_CONSULTATION";
  currentlyLocationEngineerVer:
    | "taipei"
    | "new_taipei"
    | "keelung"
    | "taoyuan"
    | "hsinchu"
    | "miaoli"
    | "taichung"
    | "changhua"
    | "nantou"
    | "yunlin"
    | "chiayi"
    | "tainan"
    | "kaohsiung"
    | "pingtung"
    | "yilan"
    | "hualien"
    | "taitung"
    | "taiwan_outside"
    | "abroad";
  haveExperienced: "true" | "false";
  budgetCurrency: "USD" | "TWD";
  name: string;
  phone: string;
  email: string;
  availableTime?: string;
  consultantTime: string;
  currentlyLocation: string;
  purpose: string;
  budget: string;
  dDay: string;
  interestedCity: string[];
  interestedFloorPlan: string[];
  whereDoYouKnowUsFrom: string;
  utm?: UTM;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Funwoo APis
 * @version 1.0
 * @contact
 *
 * The cats API description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  server = {
    /**
     * No description
     *
     * @name GetServerEnvironment
     * @request GET:/server/environment
     */
    getServerEnvironment: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/server/environment`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  crm = {
    /**
     * No description
     *
     * @tags CRM
     * @name FindAll
     * @request GET:/referrals
     */
    findAll: (params: RequestParams = {}) =>
      this.request<Referral[], any>({
        path: `/referrals`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name FindOne
     * @request GET:/referrals/{id}
     */
    findOne: (id: string, query: { platform: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/referrals/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name Update
     * @request PATCH:/referrals/{id}
     */
    update: (id: string, data: UpdateReferralDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referrals/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name Remove
     * @request DELETE:/referrals/{id}
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referrals/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name GetLineLink
     * @request GET:/referrals/line/link
     */
    getLineLink: (
      query: { listing_sid: string; source_type: "web" | "meta_ads" | "one_page_flyer" },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/referrals/line/link`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name CreateVisitor
     * @request POST:/referrals/createVisitor
     */
    createVisitor: (data: CreateLineVisitorDto, params: RequestParams = {}) =>
      this.request<ReferralLine, any>({
        path: `/referrals/createVisitor`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name GetMetaLink
     * @request GET:/referrals/meta/link
     */
    getMetaLink: (
      query: { listing_sid: string; source_type: "web" | "meta_ads" | "one_page_flyer" },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/referrals/meta/link`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name MetaWebhookGet
     * @request GET:/referrals/meta/webhook
     */
    metaWebhookGet: (
      query: { "hub.mode": string; "hub.verify_token": string; "hub.challenge": string },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/referrals/meta/webhook`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRM
     * @name MetaWebhookPost
     * @request POST:/referrals/meta/webhook
     */
    metaWebhookPost: (data: CreateMetaWebhookDto, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/referrals/meta/webhook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  asset = {
    /**
     * No description
     *
     * @tags asset
     * @name UploadImage
     * @request POST:/asset/image/upload
     * @secure
     */
    uploadImage: (
      data: { listingId?: string; tag?: string; order?: number; file?: File },
      params: RequestParams = {},
    ) =>
      this.request<ListingImage, any>({
        path: `/asset/image/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name UploadImageForBuilding
     * @request POST:/asset/image/uploadForBuilding
     * @secure
     */
    uploadImageForBuilding: (
      data: { buildingId: string; tag?: string; order?: number; file: File },
      params: RequestParams = {},
    ) =>
      this.request<BuildingImage, any>({
        path: `/asset/image/uploadForBuilding`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name UploadImageForBlog
     * @request POST:/asset/image/uploadForBlog
     * @secure
     */
    uploadImageForBlog: (data: { file: File }, params: RequestParams = {}) =>
      this.request<UploadBlogImageResultEntity, any>({
        path: `/asset/image/uploadForBlog`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name UploadImageForUserStory
     * @request POST:/asset/image/uploadForUserStory
     * @secure
     */
    uploadImageForUserStory: (data: { file: File }, params: RequestParams = {}) =>
      this.request<UploadBlogImageResultEntity, any>({
        path: `/asset/image/uploadForUserStory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AddVideo
     * @request POST:/asset/video/add
     * @secure
     */
    addVideo: (data: AddVideoDto, params: RequestParams = {}) =>
      this.request<ListingVideo, any>({
        path: `/asset/video/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name AddVideoForBuilding
     * @request POST:/asset/video/addForBuilding
     * @secure
     */
    addVideoForBuilding: (data: AddVideoForBuildingDto, params: RequestParams = {}) =>
      this.request<BuildingVideo, any>({
        path: `/asset/video/addForBuilding`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name UploadToGoogle
     * @request POST:/asset
     * @secure
     */
    uploadToGoogle: (data: { id?: string; file?: File }, params: RequestParams = {}) =>
      this.request<UploadToGoogle, any>({
        path: `/asset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name UploadAgentImg
     * @request POST:/asset/google/agentImg
     * @secure
     */
    uploadAgentImg: (data: UploadToGoogleSpecificBucketDto, params: RequestParams = {}) =>
      this.request<string[], any[]>({
        path: `/asset/google/agentImg`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name UploadToGoogleSpecificBucket
     * @request POST:/asset/google/specific-bucket
     * @secure
     */
    uploadToGoogleSpecificBucket: (data: UploadToGoogleSpecificBucketDto, params: RequestParams = {}) =>
      this.request<string[], any[]>({
        path: `/asset/google/specific-bucket`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name DeleteImage
     * @request DELETE:/asset/image/delete/{id}
     * @secure
     */
    deleteImage: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/asset/image/delete/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name DeleteBuildingImage
     * @request DELETE:/asset/image/delete-building/{id}
     * @secure
     */
    deleteBuildingImage: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/asset/image/delete-building/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset
     * @name DeleteVideo
     * @request DELETE:/asset/video/delete/{id}
     * @secure
     */
    deleteVideo: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/asset/video/delete/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  agentApi = {
    /**
     * No description
     *
     * @tags AgentApi
     * @name FindAllAgents
     * @request GET:/agent/findAll
     */
    findAllAgents: (query?: { country?: "TW" | "US" | "CA" }, params: RequestParams = {}) =>
      this.request<Agent[], any>({
        path: `/agent/findAll`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name CreateAgent
     * @request POST:/agent
     */
    createAgent: (data: CreateNewAgentDto, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/agent`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name FindAll
     * @request GET:/agent
     */
    findAll: (query?: { country?: "TW" | "US" | "CA" }, params: RequestParams = {}) =>
      this.request<Agent[], any>({
        path: `/agent`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name Update
     * @request PUT:/agent
     * @secure
     */
    update: (data: UpdateAgentDto, params: RequestParams = {}) =>
      this.request<Agent, any>({
        path: `/agent`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name UpdateAgent
     * @request POST:/agent/updateAgent
     */
    updateAgent: (data: UpdateAgentDataDto, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/agent/updateAgent`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name FindOne
     * @request GET:/agent/specific/{id}
     */
    findOne: (id: string, query?: { onlyActive?: boolean }, params: RequestParams = {}) =>
      this.request<Agent, any>({
        path: `/agent/specific/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name FindAllSids
     * @request GET:/agent/allSids
     */
    findAllSids: (query?: { onlyActive?: boolean }, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/agent/allSids`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name FindCurrentUser
     * @request GET:/agent/current
     * @secure
     */
    findCurrentUser: (params: RequestParams = {}) =>
      this.request<Agent, any>({
        path: `/agent/current`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name FindAgentResponsibleListings
     * @request GET:/agent/responsible-listings
     * @secure
     */
    findAgentResponsibleListings: (query: { agentId: string }, params: RequestParams = {}) =>
      this.request<AgentResponsibleListingsEntity[], any>({
        path: `/agent/responsible-listings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name UpdateAgentSort
     * @request PUT:/agent/sort
     * @secure
     */
    updateAgentSort: (data: UpdateAgentSortDto[], params: RequestParams = {}) =>
      this.request<Agent[], any>({
        path: `/agent/sort`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name InactiveAgent
     * @request PUT:/agent/inactive
     * @secure
     */
    inactiveAgent: (query: { agentId: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agent/inactive`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AgentApi
     * @name ActiveAgent
     * @request PUT:/agent/active
     * @secure
     */
    activeAgent: (query: { agentId: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agent/active`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),
  };
  mailApi = {
    /**
     * No description
     *
     * @tags MailApi
     * @name Create
     * @request POST:/mail/send
     */
    create: (data: CreateMailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mail/send`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailApi
     * @name SendVerifyEmail
     * @request POST:/mail/sendVerifyEmail
     */
    sendVerifyEmail: (data: SendVerifyEmailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mail/sendVerifyEmail`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MailApi
     * @name SendEmail
     * @request POST:/mail/sendMail
     */
    sendEmail: (
      data:
        | SendEmailBuyMailDto
        | SendEmailBuyHouseConsultantMailDto
        | SendEmailInterestedBuildingDto
        | SendEmailContactMailDto
        | SendEmailSellMailDto
        | SendEmailSpecificAgentMailDto
        | SendEmailSpecificTimeMailDto
        | SendEmailAbroadDto
        | SendEmailPersonalConsultationDto
        | (SendEmailBuyMailDto &
            SendEmailBuyHouseConsultantMailDto &
            SendEmailInterestedBuildingDto &
            SendEmailContactMailDto &
            SendEmailSellMailDto &
            SendEmailSpecificAgentMailDto &
            SendEmailSpecificTimeMailDto &
            SendEmailAbroadDto &
            SendEmailPersonalConsultationDto),
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/mail/sendMail`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  authApi = {
    /**
     * No description
     *
     * @tags AuthApi
     * @name IssueInstagramAuth
     * @request GET:/auth/instagram/issue
     */
    issueInstagramAuth: (params: RequestParams = {}) =>
      this.request<InstagramIssueEntity, any>({
        path: `/auth/instagram/issue`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name ConnectInstagramIdWithUser
     * @request POST:/auth/instagram/connect
     * @secure
     */
    connectInstagramIdWithUser: (data: ConnectInstagramIdWithUserDto, params: RequestParams = {}) =>
      this.request<BackyardUserEntity, any>({
        path: `/auth/instagram/connect`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name DisconnectInstagramIdWithUser
     * @request POST:/auth/instagram/disconnect
     * @secure
     */
    disconnectInstagramIdWithUser: (params: RequestParams = {}) =>
      this.request<BackyardUserEntity, any>({
        path: `/auth/instagram/disconnect`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name DeauthorizeInstagramIdWithUser
     * @request POST:/auth/instagram/deauthorize
     */
    deauthorizeInstagramIdWithUser: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/instagram/deauthorize`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name DeletionInstagramIdWithUser
     * @request POST:/auth/instagram/deletion
     */
    deletionInstagramIdWithUser: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/instagram/deletion`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name ConnectFacebookIdWithUser
     * @request POST:/auth/facebook/connect
     * @secure
     */
    connectFacebookIdWithUser: (data: ConnectFacebookIdWithUserDto, params: RequestParams = {}) =>
      this.request<BackyardUserEntity, any>({
        path: `/auth/facebook/connect`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name DisconnectFacebookIdWithUser
     * @request POST:/auth/facebook/disconnect
     * @secure
     */
    disconnectFacebookIdWithUser: (params: RequestParams = {}) =>
      this.request<BackyardUserEntity, any>({
        path: `/auth/facebook/disconnect`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name DeauthorizeFacebookIdWithUser
     * @request POST:/auth/facebook/deauthorize
     */
    deauthorizeFacebookIdWithUser: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/facebook/deauthorize`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name DeletionFacebookIdWithUser
     * @request POST:/auth/facebook/deletion
     */
    deletionFacebookIdWithUser: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/facebook/deletion`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name RefreshToken
     * @request POST:/auth/google/refresh_jwt
     */
    refreshToken: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/google/refresh_jwt`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name IssueGoogleOAuth
     * @request GET:/auth/google/issue
     */
    issueGoogleOAuth: (query: { state: string; useDeprecated?: boolean }, params: RequestParams = {}) =>
      this.request<AuthIssueGoogleOauthEntity, any>({
        path: `/auth/google/issue`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name GoogleAuthCallback
     * @request POST:/auth/google/auth/web
     */
    googleAuthCallback: (data: GoogleAuthCallbackDto, params: RequestParams = {}) =>
      this.request<BackyardUserEntity, any>({
        path: `/auth/google/auth/web`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name PwdAuth
     * @request POST:/auth/google/auth/pwd
     */
    pwdAuth: (data: PasswordAuth, params: RequestParams = {}) =>
      this.request<PwdAuthEntity, any>({
        path: `/auth/google/auth/pwd`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name VerifyByEmail
     * @request POST:/auth/verify/email
     */
    verifyByEmail: (data: VerifyEmailDto, params: RequestParams = {}) =>
      this.request<BackyardUserEntity, any>({
        path: `/auth/verify/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AuthApi
     * @name GoogleAuthMobile
     * @request POST:/auth/google/auth/mobile
     */
    googleAuthMobile: (data: GoogleAuthCallbackDto, params: RequestParams = {}) =>
      this.request<PwdAuthEntity, any>({
        path: `/auth/google/auth/mobile`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  listingApi = {
    /**
     * No description
     *
     * @tags ListingApi
     * @name GenerateSid
     * @request GET:/listing/gen/sid
     */
    generateSid: (query: { address: string }, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/listing/gen/sid`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name SearchCount
     * @request POST:/listing/count
     */
    searchCount: (data: SearchListingPaginationDto, params: RequestParams = {}) =>
      this.request<PaginationEntity, any>({
        path: `/listing/count`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name SearchList
     * @request POST:/listing/search/list
     */
    searchList: (data: SearchListingDto, params: RequestParams = {}) =>
      this.request<ListingListPaginationEntity, any>({
        path: `/listing/search/list`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name Search
     * @request POST:/listing
     */
    search: (data: SearchListingDto, params: RequestParams = {}) =>
      this.request<ListingPaginationEntity, any>({
        path: `/listing`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name FindAll
     * @request GET:/listing
     */
    findAll: (query?: { onlyActivate?: boolean }, params: RequestParams = {}) =>
      this.request<ListingDetail[], any>({
        path: `/listing`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name FindAllForEditList
     * @request GET:/listing/allForEditList
     */
    findAllForEditList: (params: RequestParams = {}) =>
      this.request<ListingForBackyardList[], any>({
        path: `/listing/allForEditList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name FindOne
     * @request GET:/listing/specific/{sid}
     */
    findOne: (sid: string, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/specific/${sid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name FindOneForBuy
     * @request GET:/listing/forBuy
     */
    findOneForBuy: (query: { listingSid: string; agentSid: string }, params: RequestParams = {}) =>
      this.request<ListingForBuy, any>({
        path: `/listing/forBuy`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name FindAllFavorite
     * @request GET:/listing/favorite
     */
    findAllFavorite: (query: { sids: string[] }, params: RequestParams = {}) =>
      this.request<ListingDetail[], any>({
        path: `/listing/favorite`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name FindAllSids
     * @request GET:/listing/allSids
     */
    findAllSids: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/listing/allSids`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name GetHashedAddress
     * @request GET:/listing/hash/address
     */
    getHashedAddress: (query: { address: string }, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/listing/hash/address`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name SearchByAddress
     * @request POST:/listing/search/byAddress
     * @secure
     */
    searchByAddress: (data: SearchListingByAddressDto, params: RequestParams = {}) =>
      this.request<ListingPaginationEntity, any>({
        path: `/listing/search/byAddress`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name CreateFromBuilding
     * @request POST:/listing/create/fromBuilding
     * @secure
     */
    createFromBuilding: (data: CreateListingFromBuildingDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/create/fromBuilding`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name CreateFromCma
     * @request POST:/listing/create/fromCMA
     * @secure
     */
    createFromCma: (data: CreateListingFromCMADto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/create/fromCMA`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name CreateUsListing
     * @request POST:/listing/create/us
     * @secure
     */
    createUsListing: (data: CreateUsListingDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/create/us`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name CreateCanadaListing
     * @request POST:/listing/create/canada
     * @secure
     */
    createCanadaListing: (data: CreateCanadaListingDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/create/canada`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name CreateFromEmptyAddress
     * @request POST:/listing/create/fromEmptyAddress
     * @secure
     */
    createFromEmptyAddress: (data: CreateListingFromEmptyAddressDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/create/fromEmptyAddress`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name Clone
     * @request POST:/listing/create/clone
     * @secure
     */
    clone: (data: CloneListingDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/create/clone`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingApi
     * @name UpdateTrafficEnv
     * @request PUT:/listing/update/traffic_env
     * @secure
     */
    updateTrafficEnv: (data: UpdateTrafficEnvDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing/update/traffic_env`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  mediaApi = {
    /**
     * No description
     *
     * @tags MediaApi
     * @name FindAll
     * @request GET:/media
     */
    findAll: (query?: { count?: number }, params: RequestParams = {}) =>
      this.request<MediaEntity[], any>({
        path: `/media`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  optionApi = {
    /**
     * No description
     *
     * @tags OptionApi
     * @name GetOptionsForBuy
     * @request GET:/option/optionsForBuy
     */
    getOptionsForBuy: (params: RequestParams = {}) =>
      this.request<GetOptionsForBuy, any>({
        path: `/option/optionsForBuy`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OptionApi
     * @name GetOptionsForBuildingFilter
     * @request GET:/option/optionsForBuildingFilter
     */
    getOptionsForBuildingFilter: (params: RequestParams = {}) =>
      this.request<GetOptionsForBuildingFilter, any>({
        path: `/option/optionsForBuildingFilter`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OptionApi
     * @name GetAllCityOptions
     * @request GET:/option/allCityOptions
     */
    getAllCityOptions: (params: RequestParams = {}) =>
      this.request<any[], any>({
        path: `/option/allCityOptions`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  bookApi = {
    /**
     * No description
     *
     * @tags BookApi
     * @name FindAllByType
     * @request GET:/book
     * @secure
     */
    findAllByType: (
      query: { type: "ALL" | "LISTING_PRESENTATION" | "SOCIAL_MEDIA" | "ONE_PAGE_FLYER" },
      params: RequestParams = {},
    ) =>
      this.request<Book[], any>({
        path: `/book`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name FindOneByBookId
     * @request GET:/book/specific/byBookId/{bookId}
     * @secure
     */
    findOneByBookId: (bookId: string, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/specific/byBookId/${bookId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name FindOneByBookType
     * @request GET:/book/specific/byBookType
     * @secure
     */
    findOneByBookType: (
      query: { type: "ALL" | "LISTING_PRESENTATION" | "SOCIAL_MEDIA" | "ONE_PAGE_FLYER" },
      params: RequestParams = {},
    ) =>
      this.request<Book, any>({
        path: `/book/specific/byBookType`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name Create
     * @request POST:/book/create
     * @secure
     */
    create: (data: CreateBookDto, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name UpdateTitle
     * @request PUT:/book/update/title
     * @secure
     */
    updateTitle: (data: UpdateBookTitleDto, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/update/title`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name UpdatePage
     * @request PUT:/book/update/page
     * @secure
     */
    updatePage: (data: UpdateBookPageDto, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/update/page`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name UpdateWholePage
     * @request PUT:/book/update/wholePage
     * @secure
     */
    updateWholePage: (data: UpdateWholeBookPageDto, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/update/wholePage`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name UpdatePageReference
     * @request PUT:/book/update/page/reference
     * @secure
     */
    updatePageReference: (data: UpdateBookPageReferenceDto, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/update/page/reference`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name ResetPage
     * @request PUT:/book/reset/page
     * @secure
     */
    resetPage: (data: ResetBookPageDto, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/reset/page`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name Delete
     * @request DELETE:/book/book/{bookId}
     * @secure
     */
    delete: (bookId: string, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/book/${bookId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BookApi
     * @name DeletePage
     * @request DELETE:/book/page/{bookId}/{pageId}
     * @secure
     */
    deletePage: (bookId: string, pageId: string, params: RequestParams = {}) =>
      this.request<Book, any>({
        path: `/book/page/${bookId}/${pageId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  templateApi = {
    /**
     * No description
     *
     * @tags TemplateApi
     * @name FindAll
     * @request GET:/template
     * @secure
     */
    findAll: (params: RequestParams = {}) =>
      this.request<TemplateEntity[], any>({
        path: `/template`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateApi
     * @name Create
     * @request POST:/template
     * @secure
     */
    create: (data: CreateTemplateDto, params: RequestParams = {}) =>
      this.request<TemplateEntity, any>({
        path: `/template`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateApi
     * @name FindOne
     * @request GET:/template/{templateId}
     * @secure
     */
    findOne: (templateId: string, params: RequestParams = {}) =>
      this.request<TemplateEntity, any>({
        path: `/template/${templateId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateApi
     * @name Delete
     * @request DELETE:/template/{templateId}
     * @secure
     */
    delete: (templateId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/template/${templateId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateApi
     * @name DuplicateTemplate
     * @request POST:/template/duplicate
     * @secure
     */
    duplicateTemplate: (data: DuplicateTemplateDto, params: RequestParams = {}) =>
      this.request<TemplateEntity, any>({
        path: `/template/duplicate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TemplateApi
     * @name Update
     * @request PUT:/template/update
     * @secure
     */
    update: (data: UpdateTemplateDto, params: RequestParams = {}) =>
      this.request<TemplateEntity, any>({
        path: `/template/update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  publicationApi = {
    /**
     * No description
     *
     * @tags PublicationApi
     * @name FindAll
     * @request GET:/publication
     * @secure
     */
    findAll: (params: RequestParams = {}) =>
      this.request<PublicationEntity[], any>({
        path: `/publication`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicationApi
     * @name FindOne
     * @request GET:/publication/{bookId}
     * @secure
     */
    findOne: (bookId: string, params: RequestParams = {}) =>
      this.request<PublicationEntity, any>({
        path: `/publication/${bookId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicationApi
     * @name CreatePage
     * @request POST:/publication/create/page
     * @secure
     */
    createPage: (data: CreatePublicationPageDto, params: RequestParams = {}) =>
      this.request<PublicationPageEntity, any>({
        path: `/publication/create/page`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicationApi
     * @name UpdatePublication
     * @request PUT:/publication/update/publication
     * @secure
     */
    updatePublication: (data: UpdatePublicationDto, params: RequestParams = {}) =>
      this.request<PublicationEntity, any>({
        path: `/publication/update/publication`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicationApi
     * @name UpdatePublicationPage
     * @request PUT:/publication/update/page
     * @secure
     */
    updatePublicationPage: (data: UpdatePublicationPageDto, params: RequestParams = {}) =>
      this.request<PublicationEntity, any>({
        path: `/publication/update/page`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicationApi
     * @name UpdatePublicationPageOrder
     * @request PUT:/publication/update/page/order
     * @secure
     */
    updatePublicationPageOrder: (data: UpdatePublicationPageOrderDto, params: RequestParams = {}) =>
      this.request<PublicationEntity, any>({
        path: `/publication/update/page/order`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicationApi
     * @name DeletePublication
     * @request DELETE:/publication/{publicationId}
     * @secure
     */
    deletePublication: (publicationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/publication/${publicationId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicationApi
     * @name DeletePublicationPage
     * @request DELETE:/publication/page/{publicationId}
     * @secure
     */
    deletePublicationPage: (publicationId: string, query: { pageId: string }, params: RequestParams = {}) =>
      this.request<PublicationEntity, any>({
        path: `/publication/page/${publicationId}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  facebookApi = {
    /**
     * No description
     *
     * @tags FacebookApi
     * @name GetInsights
     * @request GET:/facebook/insight
     * @secure
     */
    getInsights: (query: { campaignId: string; startDate: string; endDate: string }, params: RequestParams = {}) =>
      this.request<FacebookInsightEntity[], any>({
        path: `/facebook/insight`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name GetCampaigns
     * @request GET:/facebook/campaign
     * @secure
     */
    getCampaigns: (params: RequestParams = {}) =>
      this.request<FacebookCampaignEntity[], any>({
        path: `/facebook/campaign`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name GetAudiences
     * @request GET:/facebook/audience
     * @secure
     */
    getAudiences: (params: RequestParams = {}) =>
      this.request<FacebookAudienceEntity[], any>({
        path: `/facebook/audience`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name GetAudienceDetail
     * @request GET:/facebook/audience/detail/{audienceId}
     * @secure
     */
    getAudienceDetail: (audienceId: string, params: RequestParams = {}) =>
      this.request<FacebookTargetingEntity, any>({
        path: `/facebook/audience/detail/${audienceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name GetTargeting
     * @request GET:/facebook/targeting
     * @secure
     */
    getTargeting: (query?: { q?: string }, params: RequestParams = {}) =>
      this.request<GetTargetingEntity[], any>({
        path: `/facebook/targeting`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name GetPreview
     * @request GET:/facebook/preview/{creativeId}
     * @secure
     */
    getPreview: (creativeId: string, query: { ad_format: string }, params: RequestParams = {}) =>
      this.request<FacebookPreviewEntity, any>({
        path: `/facebook/preview/${creativeId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name CreateCampaign
     * @request POST:/facebook/campaign/create
     * @secure
     */
    createCampaign: (data: FacebookCreateCampaignDto, params: RequestParams = {}) =>
      this.request<FacebookCampaignEntity, FacebookCreateAPIErrorEntity>({
        path: `/facebook/campaign/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name CreateAd
     * @request POST:/facebook/ad/create
     * @secure
     */
    createAd: (data: FacebookCreateAdParamsDto, params: RequestParams = {}) =>
      this.request<FacebookAdEntity, FacebookCreateAPIErrorEntity>({
        path: `/facebook/ad/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name CreateAdSet
     * @request POST:/facebook/adSet/create
     * @secure
     */
    createAdSet: (data: FacebookCreateAdSetParamsDto, params: RequestParams = {}) =>
      this.request<FacebookAdSetEntity, FacebookCreateAPIErrorEntity>({
        path: `/facebook/adSet/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name CreateSlideShow
     * @request POST:/facebook/slideShow/create
     * @secure
     */
    createSlideShow: (data: FacebookCreateSlideShowDto, params: RequestParams = {}) =>
      this.request<CreateSlideShowDto, FacebookCreateAPIErrorEntity>({
        path: `/facebook/slideShow/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name CreateCreative
     * @request POST:/facebook/creative/create
     * @secure
     */
    createCreative: (data: FacebookCreateCreativeParamsDto, params: RequestParams = {}) =>
      this.request<FacebookCreativeEntity, FacebookCreateAPIErrorEntity>({
        path: `/facebook/creative/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name UploadImage
     * @request POST:/facebook/image/upload
     * @secure
     */
    uploadImage: (data: FacebookUploadImageDto, params: RequestParams = {}) =>
      this.request<FacebookImageEntity, any>({
        path: `/facebook/image/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name UpdateCampaign
     * @request PUT:/facebook/campaign/update
     * @secure
     */
    updateCampaign: (data: FacebookUpdateCampaignDto, params: RequestParams = {}) =>
      this.request<FacebookCampaignEntity, any>({
        path: `/facebook/campaign/update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name UpdateAd
     * @request PUT:/facebook/ad/update
     * @secure
     */
    updateAd: (data: FacebookUpdateAdParamsDto, params: RequestParams = {}) =>
      this.request<FacebookAdEntity, any>({
        path: `/facebook/ad/update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name UpdateAdSet
     * @request PUT:/facebook/adSet/update
     * @secure
     */
    updateAdSet: (data: FacebookUpdateAdSetParamsDto, params: RequestParams = {}) =>
      this.request<FacebookAdEntity, any>({
        path: `/facebook/adSet/update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name UpdateCreative
     * @request PUT:/facebook/creative/update
     * @secure
     */
    updateCreative: (data: FacebookUpdateCreativeParamsDto, params: RequestParams = {}) =>
      this.request<FacebookAdEntity, any>({
        path: `/facebook/creative/update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name DeleteCampaign
     * @request DELETE:/facebook/campaign/delete/{campaignId}
     * @secure
     */
    deleteCampaign: (campaignId: string, params: RequestParams = {}) =>
      this.request<FacebookCampaignEntity, any>({
        path: `/facebook/campaign/delete/${campaignId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name DeleteAd
     * @request DELETE:/facebook/ad/delete/{adId}
     * @secure
     */
    deleteAd: (adId: string, params: RequestParams = {}) =>
      this.request<FacebookCampaignEntity, any>({
        path: `/facebook/ad/delete/${adId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name DeleteSetAd
     * @request DELETE:/facebook/adSet/delete/{adSetId}
     * @secure
     */
    deleteSetAd: (adSetId: string, params: RequestParams = {}) =>
      this.request<FacebookCampaignEntity, any>({
        path: `/facebook/adSet/delete/${adSetId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FacebookApi
     * @name DeleteCreative
     * @request DELETE:/facebook/creative/delete/{adCreativeId}
     * @secure
     */
    deleteCreative: (adCreativeId: string, params: RequestParams = {}) =>
      this.request<FacebookCampaignEntity, any>({
        path: `/facebook/creative/delete/${adCreativeId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  crmServer = {
    /**
     * No description
     *
     * @tags CRMServer
     * @name GetLivechatRooms
     * @request GET:/crm/livechat/rooms
     * @secure
     */
    getLivechatRooms: (query: { updatedSince: string }, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/crm/livechat/rooms`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name AsyncVisitors
     * @request GET:/crm/asyncVisitors
     */
    asyncVisitors: (params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/crm/asyncVisitors`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name GetOpenGraph
     * @request GET:/crm/open-graph
     */
    getOpenGraph: (query?: { url?: string }, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/crm/open-graph`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name GetVisitorInfo
     * @request GET:/crm/getVisitorInfo
     */
    getVisitorInfo: (query: { visitorId: string }, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/crm/getVisitorInfo`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name GetQueneUsers
     * @request GET:/crm/getQueneUsers
     */
    getQueneUsers: (query: { offset: number }, params: RequestParams = {}) =>
      this.request<GetQueueUsersEntity, any>({
        path: `/crm/getQueneUsers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name GetVisitorRooms
     * @request GET:/crm/visitor/rooms
     * @secure
     */
    getVisitorRooms: (params: RequestParams = {}) =>
      this.request<CrmVisitorRoomEntity[], any>({
        path: `/crm/visitor/rooms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name FindOneAgent
     * @request GET:/crm/agent/{id}
     */
    findOneAgent: (id: string, params: RequestParams = {}) =>
      this.request<CRMAgentEntity, any>({
        path: `/crm/agent/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name GetAgentList
     * @request GET:/crm/agentList
     */
    getAgentList: (params: RequestParams = {}) =>
      this.request<CRMAgentEntity[], any>({
        path: `/crm/agentList`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name AsyncRooms
     * @request GET:/crm/asyncRooms
     */
    asyncRooms: (params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/crm/asyncRooms`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name DownloadFile
     * @request POST:/crm/downloadFile
     */
    downloadFile: (data: DownloadFile, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/crm/downloadFile`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CRMServer
     * @name NotifyAgent
     * @request POST:/crm/notify/agent
     * @secure
     */
    notifyAgent: (data: NotifyAgentDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/crm/notify/agent`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  listingEditorApi = {
    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name FindAllForUser
     * @request GET:/listing-editor/forUser
     * @secure
     */
    findAllForUser: (params: RequestParams = {}) =>
      this.request<ListingEditRecordEntity[], any>({
        path: `/listing-editor/forUser`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name FindAllReviewing
     * @request GET:/listing-editor/reviewing
     * @secure
     */
    findAllReviewing: (params: RequestParams = {}) =>
      this.request<ListingEditRecordEntity[], any>({
        path: `/listing-editor/reviewing`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name FindReviewingData
     * @request GET:/listing-editor/reviewing/data/{recordId}
     * @secure
     */
    findReviewingData: (recordId: string, params: RequestParams = {}) =>
      this.request<ListingEditorReviewingDataEntity, any>({
        path: `/listing-editor/reviewing/data/${recordId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name StartReview
     * @request POST:/listing-editor/start/review
     * @secure
     */
    startReview: (query: { recordId: string }, params: RequestParams = {}) =>
      this.request<ListingEditRecordEntity, any>({
        path: `/listing-editor/start/review`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name CreateRecord
     * @request POST:/listing-editor/create/record
     * @secure
     */
    createRecord: (data: CreateListingRecordDto, params: RequestParams = {}) =>
      this.request<CreateListingRecordEntity, any>({
        path: `/listing-editor/create/record`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name SubmitEditRecord
     * @request POST:/listing-editor/submit
     * @secure
     */
    submitEditRecord: (data: SubmitListingRecordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/listing-editor/submit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name ApproveReview
     * @request POST:/listing-editor/approve
     * @secure
     */
    approveReview: (data: ApproveListingRecordDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing-editor/approve`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name ForceInactive
     * @request PUT:/listing-editor/forceInactive
     * @secure
     */
    forceInactive: (data: ForceInactiveListingDto, params: RequestParams = {}) =>
      this.request<ListingDetail, any>({
        path: `/listing-editor/forceInactive`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name FindInSortingReviewListing
     * @request GET:/listing-editor/sorting
     * @secure
     */
    findInSortingReviewListing: (query: { recordId: string }, params: RequestParams = {}) =>
      this.request<ListingDetail[], any>({
        path: `/listing-editor/sorting`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name StartSorting
     * @request POST:/listing-editor/sorting/start
     * @secure
     */
    startSorting: (params: RequestParams = {}) =>
      this.request<StartSortingListingEntity, any>({
        path: `/listing-editor/sorting/start`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name SaveSortingResult
     * @request POST:/listing-editor/sorting/save
     * @secure
     */
    saveSortingResult: (data: SaveListingSortingResultDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/listing-editor/sorting/save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name ApproveSorting
     * @request PUT:/listing-editor/sorting/approve
     * @secure
     */
    approveSorting: (data: ApproveListingSortingDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/listing-editor/sorting/approve`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ListingEditorApi
     * @name DropAllImages
     * @request PUT:/listing-editor/dropAllImages
     * @secure
     */
    dropAllImages: (query: { recordId: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/listing-editor/dropAllImages`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),
  };
  homepageApi = {
    /**
     * No description
     *
     * @tags HomepageApi
     * @name GetHomepageInfo
     * @request GET:/home-page
     */
    getHomepageInfo: (params: RequestParams = {}) =>
      this.request<HomePageEntity, any>({
        path: `/home-page`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  jobApi = {
    /**
     * No description
     *
     * @tags JobApi
     * @name FindAll
     * @request GET:/job
     */
    findAll: (params: RequestParams = {}) =>
      this.request<JobEntity[], any>({
        path: `/job`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags JobApi
     * @name FindOne
     * @request GET:/job/{sid}
     */
    findOne: (sid: string, params: RequestParams = {}) =>
      this.request<JobEntity, any>({
        path: `/job/${sid}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags users
     * @name Create
     * @request POST:/users
     */
    create: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name FindAll
     * @request GET:/users
     */
    findAll: (params: RequestParams = {}) =>
      this.request<any, User>({
        path: `/users`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name AddUserRole
     * @request POST:/users/addRole
     */
    addUserRole: (data: CreateUserRoleDto, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/users/addRole`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name RegisterUser
     * @request POST:/users/register
     */
    registerUser: (data: RegisterUserDto, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/users/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name FindOne
     * @request GET:/users/{id}
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name Update
     * @request PATCH:/users/{id}
     */
    update: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name Remove
     * @request DELETE:/users/{id}
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  pluginApi = {
    /**
     * No description
     *
     * @tags PluginApi
     * @name UploadFigmaTemplate
     * @request POST:/plugin/figma/template
     */
    uploadFigmaTemplate: (data: { file?: File }, params: RequestParams = {}) =>
      this.request<TemplateEntity, any>({
        path: `/plugin/figma/template`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  referralSourceApi = {
    /**
     * No description
     *
     * @tags ReferralSourceAPI
     * @name Create
     * @request POST:/referral-source
     */
    create: (data: CreateReferralSourceDto, params: RequestParams = {}) =>
      this.request<CreateReferralSourceDto[], any>({
        path: `/referral-source`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferralSourceAPI
     * @name FindAll
     * @request GET:/referral-source
     */
    findAll: (params: RequestParams = {}) =>
      this.request<CreateReferralSourceDto[], any>({
        path: `/referral-source`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferralSourceAPI
     * @name FindOne
     * @request GET:/referral-source/{id}
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referral-source/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferralSourceAPI
     * @name Update
     * @request PATCH:/referral-source/{id}
     */
    update: (id: string, data: UpdateReferralSourceDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referral-source/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferralSourceAPI
     * @name Remove
     * @request DELETE:/referral-source/{id}
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referral-source/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  referralApplication = {
    /**
     * No description
     *
     * @name Create
     * @request POST:/referral-application
     */
    create: (data: CreateReferralApplicationDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referral-application`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FindAll
     * @request GET:/referral-application
     */
    findAll: (params: RequestParams = {}) =>
      this.request<any, ReferralApplication[]>({
        path: `/referral-application`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name FindOne
     * @request GET:/referral-application/{id}
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referral-application/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Update
     * @request PATCH:/referral-application/{id}
     */
    update: (id: string, data: UpdateReferralApplicationDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referral-application/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Remove
     * @request DELETE:/referral-application/{id}
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/referral-application/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  referralLink = {
    /**
     * No description
     *
     * @name Create
     * @request POST:/referral-link
     */
    create: (data: CreateReferralLinkDto, params: RequestParams = {}) =>
      this.request<void, ReferralLink[]>({
        path: `/referral-link`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name FindAll
     * @request GET:/referral-link
     */
    findAll: (params: RequestParams = {}) =>
      this.request<any, ReferralLink[]>({
        path: `/referral-link`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name AssignAgent
     * @request POST:/referral-link/assignAgent
     */
    assignAgent: (data: AssignAgentDto, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/referral-link/assignAgent`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name FindOne
     * @request GET:/referral-link/{id}
     */
    findOne: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/referral-link/${id}`,
        method: "GET",
        ...params,
      }),
  };
  webhook = {
    /**
     * No description
     *
     * @tags webhook
     * @name GetVisitor
     * @request GET:/webhook/getVisitor
     */
    getVisitor: (query: { visitorId: string }, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/webhook/getVisitor`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook
     * @name LineWebhook
     * @request POST:/webhook/line
     */
    lineWebhook: (data: LineWebhookDTO, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/webhook/line`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook
     * @name Create
     * @request POST:/webhook
     */
    create: (params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/webhook`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook
     * @name DirectMessage
     * @request POST:/webhook/directMessage
     */
    directMessage: (data: DirectMessageDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/webhook/directMessage`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook
     * @name LineMediaHandler
     * @request POST:/webhook/lineMediaHandler
     */
    lineMediaHandler: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/webhook/lineMediaHandler`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags webhook
     * @name DirectMessageWithFile
     * @request POST:/webhook/directMessageWithFile
     */
    directMessageWithFile: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/webhook/directMessageWithFile`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  permissionApi = {
    /**
     * No description
     *
     * @tags PermissionAPI
     * @name FindAll
     * @request GET:/permission
     */
    findAll: (params: RequestParams = {}) =>
      this.request<BackyardUserEntity[], any>({
        path: `/permission`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PermissionAPI
     * @name FindRoleType
     * @request GET:/permission/roleTypes
     */
    findRoleType: (params: RequestParams = {}) =>
      this.request<UserRoleTypeEntity[], any>({
        path: `/permission/roleTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PermissionAPI
     * @name FindOne
     * @request GET:/permission/me
     */
    findOne: (params: RequestParams = {}) =>
      this.request<Permission, any>({
        path: `/permission/me`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PermissionAPI
     * @name Update
     * @request PATCH:/permission/{id}
     */
    update: (id: string, data: UpdatePermissionDto, params: RequestParams = {}) =>
      this.request<UserRoleEntity[], any>({
        path: `/permission/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PermissionAPI
     * @name Remove
     * @request DELETE:/permission/{id}
     */
    remove: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/permission/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  chatApi = {
    /**
     * No description
     *
     * @tags ChatApi
     * @name SearchSpecificPrivateRoom
     * @request POST:/chat/private
     * @secure
     */
    searchSpecificPrivateRoom: (data: FindPrivateRoomDto, params: RequestParams = {}) =>
      this.request<PrivateRoomEntity, any>({
        path: `/chat/private`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ChatApi
     * @name SearchPrivateMessageHistory
     * @request POST:/chat/search/private/history
     * @secure
     */
    searchPrivateMessageHistory: (data: SearchPrivateMessageHistoryDto, params: RequestParams = {}) =>
      this.request<PrivateMessageHistoryEntity, any>({
        path: `/chat/search/private/history`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ChatApi
     * @name FindRoomInfo
     * @request GET:/chat/room/{roomId}
     * @secure
     */
    findRoomInfo: (roomId: string, params: RequestParams = {}) =>
      this.request<RoomInfoEntity, any>({
        path: `/chat/room/${roomId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ChatApi
     * @name FindUserInfo
     * @request GET:/chat/user/{userId}
     * @secure
     */
    findUserInfo: (userId: string, params: RequestParams = {}) =>
      this.request<RocketChatUserInfoEntity, any>({
        path: `/chat/user/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ChatApi
     * @name FindJoinedPrivateRoom
     * @request GET:/chat/private/joined
     * @secure
     */
    findJoinedPrivateRoom: (params: RequestParams = {}) =>
      this.request<JoinedPrivateRoomListResultEntity, any>({
        path: `/chat/private/joined`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  googleMapApi = {
    /**
     * No description
     *
     * @tags GoogleMapApi
     * @name FindPlacesAutocomplete
     * @request POST:/google-map/places/autocomplete
     */
    findPlacesAutocomplete: (data: FindPlacesAutocompleteDto, params: RequestParams = {}) =>
      this.request<FindPlacesAutocompleteEntity[], any>({
        path: `/google-map/places/autocomplete`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GoogleMapApi
     * @name FindDirectionTime
     * @request POST:/google-map/direction/time
     */
    findDirectionTime: (data: FindDirectionTimeDto, params: RequestParams = {}) =>
      this.request<FindDirectionTimeEntity, any>({
        path: `/google-map/direction/time`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  pipedriveApi = {
    /**
     * No description
     *
     * @tags PipedriveApi
     * @name Webhook
     * @request POST:/pipedrive/webhook
     */
    webhook: (data: PipedriveWebhookDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/pipedrive/webhook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipedriveApi
     * @name FindAllProducts
     * @request GET:/pipedrive/products
     * @secure
     */
    findAllProducts: (params: RequestParams = {}) =>
      this.request<PipedriveGetAllProductsEntity, any>({
        path: `/pipedrive/products`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipedriveApi
     * @name FindProduct
     * @request GET:/pipedrive/products/{id}
     * @secure
     */
    findProduct: (id: number, params: RequestParams = {}) =>
      this.request<PipedriveGetSpecificProductEntity, any>({
        path: `/pipedrive/products/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipedriveApi
     * @name FindSpecificProductDeals
     * @request GET:/pipedrive/products/deals/{id}
     * @secure
     */
    findSpecificProductDeals: (id: number, params: RequestParams = {}) =>
      this.request<PipedriveGetAllProductDealEntity, any>({
        path: `/pipedrive/products/deals/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipedriveApi
     * @name FindAllProductFields
     * @request GET:/pipedrive/productFields
     * @secure
     */
    findAllProductFields: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/pipedrive/productFields`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipedriveApi
     * @name FindAllDealFields
     * @request GET:/pipedrive/dealFields
     * @secure
     */
    findAllDealFields: (params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/pipedrive/dealFields`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipedriveApi
     * @name FindSpecificDealProducts
     * @request GET:/pipedrive/deals/products/{id}
     * @secure
     */
    findSpecificDealProducts: (id: number, params: RequestParams = {}) =>
      this.request<PipedriveDealRelatedProductsEntity, any>({
        path: `/pipedrive/deals/products/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipedriveApi
     * @name FindAllStages
     * @request GET:/pipedrive/stages
     * @secure
     */
    findAllStages: (params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/pipedrive/stages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  productApi = {
    /**
     * No description
     *
     * @tags ProductApi
     * @name GetAvailableLocality
     * @request GET:/product/available-locality
     */
    getAvailableLocality: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/product/available-locality`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductApi
     * @name GetAvailableAgent
     * @request GET:/product/available-agent
     */
    getAvailableAgent: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/product/available-agent`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductApi
     * @name GetAvailableDealAgent
     * @request GET:/product/available-deal-agent
     */
    getAvailableDealAgent: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/product/available-deal-agent`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductApi
     * @name SearchProduct
     * @request POST:/product/search
     * @secure
     */
    searchProduct: (data?: SearchProductDto, params: RequestParams = {}) =>
      this.request<ProductIncludeProductDealsEntity[], any>({
        path: `/product/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductApi
     * @name SearchProductDeals
     * @request POST:/product/deals/search
     * @secure
     */
    searchProductDeals: (data?: SearchProductDealsDto, params: RequestParams = {}) =>
      this.request<ProductDealsIncludeProductEntity[], any>({
        path: `/product/deals/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  countries = {
    /**
     * No description
     *
     * @name Create
     * @request POST:/countries
     */
    create: (data: CreateCountryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/countries`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name FindAll
     * @request GET:/countries
     */
    findAll: (params: RequestParams = {}) =>
      this.request<Country[], any>({
        path: `/countries`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Update
     * @request PATCH:/countries/{id}
     */
    update: (id: string, data: UpdateCountryDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/countries/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  systemApi = {
    /**
     * No description
     *
     * @tags SystemApi
     * @name CreateStaticOptionByCategory
     * @request POST:/system/staticOption
     * @secure
     */
    createStaticOptionByCategory: (data: CreateStaticOptionDto, params: RequestParams = {}) =>
      this.request<StaticOptionEntity, any>({
        path: `/system/staticOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemApi
     * @name GetLocationData
     * @request GET:/system/staticOption/locationData
     */
    getLocationData: (params: RequestParams = {}) =>
      this.request<LocationDataEntity, any>({
        path: `/system/staticOption/locationData`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemApi
     * @name GetPipedriveConsumerLocationData
     * @request GET:/system/staticOption/pipedriveConsumerLocationData
     */
    getPipedriveConsumerLocationData: (params: RequestParams = {}) =>
      this.request<PipedriveLocationOptionDataEntity, any>({
        path: `/system/staticOption/pipedriveConsumerLocationData`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemApi
     * @name FindAllStaticOptionsByCategory
     * @request GET:/system/staticOption/byCategory
     */
    findAllStaticOptionsByCategory: (
      query: { category: "CONFERENCE_LOCATION" | "BANNER" },
      params: RequestParams = {},
    ) =>
      this.request<StaticOptionEntity[], any>({
        path: `/system/staticOption/byCategory`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemApi
     * @name CheckStaticOptionValueDuplicatedInSpecificCategory
     * @request GET:/system/staticOption/duplicateCheck
     */
    checkStaticOptionValueDuplicatedInSpecificCategory: (query: { value: string }, params: RequestParams = {}) =>
      this.request<CheckStaticOptionValueDuplicatedInSpecificCategoryEntity, any>({
        path: `/system/staticOption/duplicateCheck`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemApi
     * @name GetBanner
     * @request GET:/system/staticOption/banner
     */
    getBanner: (query: { page: "HOMEPAGE" | "ABROAD" | "SELL" }, params: RequestParams = {}) =>
      this.request<StaticOptionEntity, any>({
        path: `/system/staticOption/banner`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemApi
     * @name UpdateBanner
     * @request PUT:/system/staticOption/banner
     * @secure
     */
    updateBanner: (data: UpdatePageBannerDto, params: RequestParams = {}) =>
      this.request<StaticOptionEntity, any>({
        path: `/system/staticOption/banner`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemApi
     * @name UpdateStaticOptionsByCategory
     * @request PUT:/system/staticOption/{id}
     * @secure
     */
    updateStaticOptionsByCategory: (id: string, data: UpdateStaticOptionDto, params: RequestParams = {}) =>
      this.request<StaticOptionEntity, any>({
        path: `/system/staticOption/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  buildingApi = {
    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetAllBuildingForBackyard
     * @request GET:/building/backyard/all
     * @secure
     */
    getAllBuildingForBackyard: (query?: { onlyActivate?: boolean }, params: RequestParams = {}) =>
      this.request<EditBuildingDetailEntity[], any>({
        path: `/building/backyard/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetListingRelatedBuildingInformation
     * @request GET:/building/backyard/listingRelated
     */
    getListingRelatedBuildingInformation: (query: { buildingId: string }, params: RequestParams = {}) =>
      this.request<ListingRelatedBuildingInformation, any>({
        path: `/building/backyard/listingRelated`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetBuildingShowcases
     * @request GET:/building/entrance
     */
    getBuildingShowcases: (query?: { countries?: string[] }, params: RequestParams = {}) =>
      this.request<BuildingShowcaseEntity[], any>({
        path: `/building/entrance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetBuildingSids
     * @request GET:/building/entrance/sids
     */
    getBuildingSids: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/building/entrance/sids`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetShowcaseDetailsById
     * @request GET:/building/entrance/byId/{id}
     */
    getShowcaseDetailsById: (id: string, query?: { specificAgentSid?: string }, params: RequestParams = {}) =>
      this.request<BuildingShowcaseDetailsEntity, any>({
        path: `/building/entrance/byId/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetShowcaseDetails
     * @request GET:/building/entrance/{sid}
     */
    getShowcaseDetails: (sid: string, query?: { specificAgentSid?: string }, params: RequestParams = {}) =>
      this.request<BuildingShowcaseDetailsEntity, any>({
        path: `/building/entrance/${sid}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name CreateTaiwanBuilding
     * @request POST:/building/create/taiwan
     * @secure
     */
    createTaiwanBuilding: (data: CreateTaiwanBuildingDto, params: RequestParams = {}) =>
      this.request<EditBuildingDetailEntity, any>({
        path: `/building/create/taiwan`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name CreateUsBuilding
     * @request POST:/building/create/us
     * @secure
     */
    createUsBuilding: (data: CreateUSBuildingDto, params: RequestParams = {}) =>
      this.request<EditBuildingDetailEntity, any>({
        path: `/building/create/us`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name CreateCanadaBuilding
     * @request POST:/building/create/canada
     * @secure
     */
    createCanadaBuilding: (data: CreateCanadaBuildingDto, params: RequestParams = {}) =>
      this.request<EditBuildingDetailEntity, any>({
        path: `/building/create/canada`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name CreateBuildingEditRecord
     * @request POST:/building/edit/record
     * @secure
     */
    createBuildingEditRecord: (data: CreateBuildingEditRecordDto, params: RequestParams = {}) =>
      this.request<CreateBuildingEditRecordEntity, any>({
        path: `/building/edit/record`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetEditingRecord
     * @request GET:/building/edit/record/{recordId}
     * @secure
     */
    getEditingRecord: (recordId: string, params: RequestParams = {}) =>
      this.request<BuildingEditingRecordEntity, any>({
        path: `/building/edit/record/${recordId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name GetTrafficDistance
     * @request GET:/building/traffic/distance
     * @secure
     */
    getTrafficDistance: (
      query: { buildingId: string; name: string; category: string; lat: number; lng: number },
      params: RequestParams = {},
    ) =>
      this.request<BuildingTrafficEnv, any>({
        path: `/building/traffic/distance`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name CreateRecordHistory
     * @request POST:/building/edit/recordHistory/{recordId}
     * @secure
     */
    createRecordHistory: (recordId: string, data: CreateRecordHistoryDto, params: RequestParams = {}) =>
      this.request<BuildingEditRecordHistory, any>({
        path: `/building/edit/recordHistory/${recordId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name PublishBuildingRecord
     * @request POST:/building/edit/publish/{recordId}
     * @secure
     */
    publishBuildingRecord: (recordId: string, params: RequestParams = {}) =>
      this.request<EditBuildingDetailEntity, any>({
        path: `/building/edit/publish/${recordId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name FindInSortingReviewBuilding
     * @request GET:/building/sorting
     */
    findInSortingReviewBuilding: (query: { recordId: string }, params: RequestParams = {}) =>
      this.request<EditBuildingDetailEntity[], any>({
        path: `/building/sorting`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name StartSorting
     * @request POST:/building/sorting/start
     */
    startSorting: (params: RequestParams = {}) =>
      this.request<StartSortingBuildingEntity, any>({
        path: `/building/sorting/start`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name SaveSortingResult
     * @request POST:/building/sorting/save
     */
    saveSortingResult: (data: SaveBuildingSortingResultDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/building/sorting/save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name ApproveSorting
     * @request PUT:/building/sorting/approve
     */
    approveSorting: (data: ApproveBuildingSortingDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/building/sorting/approve`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name DropAllImages
     * @request PUT:/building/drop/allImages
     */
    dropAllImages: (query: { recordId: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/building/drop/allImages`,
        method: "PUT",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BuildingApi
     * @name SyncBuildingsToListing
     * @request PUT:/building/sync/buildingsToListing
     */
    syncBuildingsToListing: (data: { buildingId: string; listingId: string }, params: RequestParams = {}) =>
      this.request<Decimal, any>({
        path: `/building/sync/buildingsToListing`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  blog = {
    /**
     * No description
     *
     * @name GetPublishedPosts
     * @request GET:/blog/published
     */
    getPublishedPosts: (query?: { category?: "MEDIA" | "TREND" }, params: RequestParams = {}) =>
      this.request<GetPublishedPostResponseEntity, any>({
        path: `/blog/published`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPublishedPost
     * @request GET:/blog/published/id/{id}
     */
    getPublishedPost: (id: string, params: RequestParams = {}) =>
      this.request<PublishedPostDetailEntity, any>({
        path: `/blog/published/id/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPublishedPostByUuid
     * @request GET:/blog/published/uuid/{uuid}
     */
    getPublishedPostByUuid: (uuid: string, params: RequestParams = {}) =>
      this.request<PublishedPostDetailEntity, any>({
        path: `/blog/published/uuid/${uuid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetRecommendedPosts
     * @request GET:/blog/recommended/id/{id}
     */
    getRecommendedPosts: (id: string, query: { count: number }, params: RequestParams = {}) =>
      this.request<GetPublishedPostResponseEntity, any>({
        path: `/blog/recommended/id/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CheckUuid
     * @request GET:/blog/check/{id}
     */
    checkUuid: (id: string, query: { uuid: string }, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/blog/check/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPost
     * @request GET:/blog/get/{modifyingId}
     */
    getPost: (modifyingId: string, params: RequestParams = {}) =>
      this.request<PostDetailEntity, any>({
        path: `/blog/get/${modifyingId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPosts
     * @request GET:/blog/get
     */
    getPosts: (query?: { category?: "MEDIA" | "TREND" }, params: RequestParams = {}) =>
      this.request<GetPostsEntity, any>({
        path: `/blog/get`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreatePost
     * @request POST:/blog
     */
    createPost: (data: CreatePostDto, params: RequestParams = {}) =>
      this.request<PostEntity, any>({
        path: `/blog`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name IssueModify
     * @request POST:/blog/{id}
     */
    issueModify: (id: string, params: RequestParams = {}) =>
      this.request<IssuePostModifyingEntity, any>({
        path: `/blog/${id}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ModifyPost
     * @request PUT:/blog/modify/{id}
     */
    modifyPost: (id: string, data: UpdatePostDto, params: RequestParams = {}) =>
      this.request<ModifyingVersionPostEntity, any>({
        path: `/blog/modify/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name TogglePostActive
     * @request PUT:/blog/active/{id}
     */
    togglePostActive: (id: string, params: RequestParams = {}) =>
      this.request<PostEntity, any>({
        path: `/blog/active/${id}`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PublishModify
     * @request PUT:/blog/publish/{modifyId}
     */
    publishModify: (modifyId: string, params: RequestParams = {}) =>
      this.request<PostDetailEntity, any>({
        path: `/blog/publish/${modifyId}`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOrder
     * @request PUT:/blog/update/order
     */
    updateOrder: (data: UpdateOrderItemDto[], params: RequestParams = {}) =>
      this.request<GetPostsEntity, any>({
        path: `/blog/update/order`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeletePost
     * @request PUT:/blog/delete/{id}
     */
    deletePost: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/blog/delete/${id}`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @name ConnectListing
     * @request PUT:/blog/connect/listing
     */
    connectListing: (data: ConnectListingDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/blog/connect/listing`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  userStory = {
    /**
     * No description
     *
     * @name GetShowcases
     * @request GET:/user-story/showcase/{page}
     */
    getShowcases: (page: "HOME" | "SELL" | "ABROAD", params: RequestParams = {}) =>
      this.request<UserStoryShowcaseEntity[], any>({
        path: `/user-story/showcase/${page}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUserStories
     * @request GET:/user-story/management/page/{page}
     */
    getUserStories: (page: "HOME" | "SELL" | "ABROAD", params: RequestParams = {}) =>
      this.request<UserStoryEntity[], any>({
        path: `/user-story/management/page/${page}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUserStory
     * @request GET:/user-story/management/id/{id}
     */
    getUserStory: (id: string, params: RequestParams = {}) =>
      this.request<UserStoryEntity, any>({
        path: `/user-story/management/id/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateUserStory
     * @request POST:/user-story/management
     */
    createUserStory: (data: CreateUserStoryDto, params: RequestParams = {}) =>
      this.request<UserStoryEntity, any>({
        path: `/user-story/management`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CloneUserStory
     * @request POST:/user-story/management/clone
     */
    cloneUserStory: (data: CloneUserStoryDto, params: RequestParams = {}) =>
      this.request<UserStoryEntity, any>({
        path: `/user-story/management/clone`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateUserStory
     * @request PUT:/user-story/management/update/{id}
     */
    updateUserStory: (id: string, data: UpdateUserStoryDto, params: RequestParams = {}) =>
      this.request<UserStoryEntity, any>({
        path: `/user-story/management/update/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name Rearrange
     * @request PUT:/user-story/management/rearrange
     */
    rearrange: (data: UpdateUserStoryOrderRequestDto, params: RequestParams = {}) =>
      this.request<UserStoryEntity[], any>({
        path: `/user-story/management/rearrange`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ReleaseUserStory
     * @request PUT:/user-story/management/release/{id}
     */
    releaseUserStory: (id: string, params: RequestParams = {}) =>
      this.request<UserStoryEntity, any>({
        path: `/user-story/management/release/${id}`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteUserStory
     * @request DELETE:/user-story/management/{id}
     */
    deleteUserStory: (id: string, params: RequestParams = {}) =>
      this.request<UserStoryEntity, any>({
        path: `/user-story/management/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  edmApi = {
    /**
     * No description
     *
     * @tags EdmApi
     * @name Subscribe
     * @request POST:/edm/subscribe
     */
    subscribe: (data: SubscribeEdmDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/edm/subscribe`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
